import { templates as templates_task } from "@yulintu/freesia-design-task";
// import { templates as templates_log } from "@yulintu/freesia-design-log";
import templates_log from "./framework/freesia-design-log/templates";
import templates_message from "./framework/freesia-design-message/templates";
import templates_openapi from "./framework/freesia-design-openapi/templates";

import Echo from "./components/pages/Echo";
import SendNotifyToMe from "./components/addons/SendNotifyToMe";
import LoadDataBeforeRender from "./components/addons/LoadDataBeforeRender";

const templates = [

    ...templates_task,
    ...templates_log,
    ...templates_message,
    ...templates_openapi,

    {
        name: "echo",
        // loader: () => import("./components/pages/Echo"),
        Component: Echo,
        pure: true,
    },

    {
        name: "send-notify-to-me",
        Component: SendNotifyToMe,
        pure: true,
    },

    {
        name: "load-data-before-render",
        Component: LoadDataBeforeRender,
        pure: true,
    },

    {
        name: "intro",
        loader: () => import("./components/pages/Intro"),
    },
    {
        name: "helloworld",
        loader: () => import("./components/pages/QuickStart/Helloworld"),
    },
    {
        name: "changetheme",
        loader: () => import("./components/pages/QuickStart/ChangeTheme"),
    },
    {
        name: "newpage",
        loader: () => import("./components/pages/QuickStart/NewPage"),
    },
    {
        name: "newpage/dashboard",
        loader: () => import("./components/pages/QuickStart/NewPage/Dashboard"),
    },
    {
        name: "changecontenttheme",
        loader: () => import("./components/pages/QuickStart/ChangeContentTheme"),
    },
    {
        name: "needlogin",
        loader: () => import("./components/pages/QuickStart/NeedLogin"),
    },
    {
        name: "needlogin/dashboard",
        loader: () => import("./components/pages/QuickStart/NeedLogin/Dashboard"),
    },
    {
        name: "changerootroutepath",
        loader: () => import("./components/pages/QuickStart/ChangeRootRoutePath"),
    },
    {
        name: "usestaticresources",
        loader: () => import("./components/pages/QuickStart/UseStaticResources"),
    },
    {
        name: "usethemestyle",
        loader: () => import("./components/pages/QuickStart/UseThemeStyle"),
    },
    {
        name: "routepermission",
        loader: () => import("./components/pages/QuickStart/RoutePermission"),
    },


    {
        name: "samples/intro",
        loader: () => import("./components/pages/Samples/Intro"),
    },
    {
        name: "samples/pro-card",
        loader: () => import("./components/pages/Samples/ProCard"),
    },
    {
        name: "samples/dashboard/monitor",
        loader: () => import("./components/pages/Samples/Dashboard/Monitor"),
    },













    {
        name: "tests/propertygridkv",
        loader: () => import("./components/pages/Tests/PropertyGridKv"),
    },
    {
        name: "tests/propertygridform",
        loader: () => import("./components/pages/Tests/PropertyGridForm"),
    },
    {
        name: "tests/datascreenpage",
        loader: () => import("./components/pages/Tests/DataScreenPage"),
    },
]

export default templates;