import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Switch } from 'react-router-dom';
import { WidthProvider, Responsive } from "react-grid-layout";
import { Breadcrumb, } from "antd";

import { Application, Bootstrap } from "@yulintu/freesia-bootstrap";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import "./index.less";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DraggableGrid = function (props) {

    const context = useContext(Application.Context);

    const [busy, setBusy] = useState(true);
    const [manifests, setManifests] = useState([]);
    const [items, setItems] = useState([]);

    const [layouts, setLayouts] = useState({});

    useEffect(() => {

        if (!props.layouts)
            return;

        for (const key in props.layouts) {
            props.layouts[key].forEach(c => c.i = c.i + "");
        }

        setLayouts(props.layouts);

    }, [props.layouts]);


    useEffect(() => {

        console.log(layouts);

    }, [layouts]);

    useEffect(() => {
        setBusy(true);
        Bootstrap.resolve(props.metadata.children, props.metadata).then(data => {
            setManifests(data);
        }).finally(() => {
            setBusy(false);
        });
    }, [props.metadata]);

    useEffect(() => {
        setBusy(true);
        Bootstrap.resolve(props.metadata.items, props.metadata).then(data => {
            setItems(data);
        }).finally(() => {
            setBusy(false);
        });
    }, [props.metadata]);

    useEffect(() => {

        if (manifests && manifests.length)
            Bootstrap.resetPermission(manifests, { userInfo: context.global.userInfo });

    }, [props.metadata, manifests, context.global.userInfo]);

    const setGlobal = context.setGlobal;
    const onLoginSuccess = useCallback((userInfo) => {
        setGlobal({ userInfo });
    }, [setGlobal]);

    const onLayoutChange = (layout, layouts) => {
        setLayouts(layouts);
    }

    const renderContent = () => {

        return (
            <div className={"freedesign layout-draggable-grid " + (props.metadata.props?.className || "")} style={{ paddingTop: props.showBreadcrumb ? 20 : 0, ...props.containerStyle }}>
                {props.showBreadcrumb ? <Breadcrumb {...props.metadata?.breadcrumb} /> : null}
                {
                    items?.length ? (
                        <ResponsiveReactGridLayout
                            className="layout"
                            cols={{ lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }}
                            rowHeight={30}
                            margin={[24, 24]}
                            style={{ marginTop: props.showBreadcrumb ? -4 : 0 }}
                            {...props.metadata.props}
                            layouts={layouts}
                            onLayoutChange={(layout, layouts) =>
                                onLayoutChange(layout, layouts)
                            }
                        >
                            {
                                (items || []).map((c, i) => {

                                    let component = null;
                                    if (typeof c.template === "string")
                                        component = context?.factory?.load(c.template, { ...c.props, metadata: c }, { pure: true });

                                    return component ? (
                                        <div key={i + ""} className={props.itemStyle || props.itemType || "card"} {...c.gridItemProps}>
                                            {component}
                                        </div>
                                    ) : null;
                                }).filter(c => c)
                            }

                        </ResponsiveReactGridLayout>
                    ) : null
                }

                <div className='layout-grid-children'>
                    <Switch>
                        {
                            Bootstrap.routes(context.factory, manifests, props.metadata, {
                                config: context.config,
                                onLoginSuccess: onLoginSuccess
                            })
                        }
                    </Switch>
                </div>

            </div>
        );
    }

    return busy ? <>
        <div></div>
    </> : renderContent();
}

export default DraggableGrid;