import moment from "moment";
import { Api as _Api } from "@yulintu/freesia-requests";
import ApiCatchHandler from "../ApiCatchHandler";

class BaseApi extends _Api {
    static thenHandlers = []
    static catchHandlers = []

    static buildPredicate(attrs, ...exts) {

        let predicate = "";
        for (const key in attrs) {

            const value = attrs[key];
            if (value === null || value === undefined)
                continue;

            if (predicate)
                predicate += " and ";

            if (typeof value === "string")
                predicate += `${key} = '${value}'`;
            if (typeof value === "number")
                predicate += `${key} = ${value}`;
            if (value instanceof moment)
                predicate += `${key} = datetime('${value.format('YYYY-MM-DD HH:mm:ss')}')`;
        }

        if (exts && exts.length) {
            exts.forEach(c => {
                if (!c)
                    return;
                if (predicate)
                    predicate += " and ";
                predicate += `(${c})`;
            });
        }

        return predicate;
    }
}

BaseApi.catch(ApiCatchHandler);

export default BaseApi;