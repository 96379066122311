import React, { useContext, useEffect, useState } from 'react';

import ListGrid from "../../../components/ListGrid";
import AppGalleryGalleryItem from "./AppGalleryGalleryItem";

import NoData from "../../../components/NoData";

import { Application, Bootstrap } from "@yulintu/freesia-bootstrap";

export default function AppGallerySection(props) {

    const context = useContext(Application.Context);

    return <>
        {
            props.title !== false ? (
                <div className='app-gallery-title'>
                    <div className='title-text'>{props.title}</div>
                </div>) : null
        }
        <ListGrid
            className="list-grid-v4-h grafana white alpha30 list-grid--app-gallery"
            nodata={<NoData />}
        >
            {
                (props.apps || []).filter(c => c.enabled !== false).map?.(c => {
                    return (
                        <ListGrid.GalleryItem
                            key={c.name || c.title || c.alias}
                            canCheck={false}
                            onClick={e => props.onClick?.(c)}>
                            <AppGalleryGalleryItem data={c}></AppGalleryGalleryItem>
                        </ListGrid.GalleryItem>
                    );
                })
            }
        </ ListGrid >
    </>;
}