import React, { useContext } from 'react';
import { Button, Tooltip } from 'antd';
import {Lang} from "@yulintu/freesia-react-component-common";
import { Application, resource } from "@yulintu/freesia-bootstrap";

import "./index.less";

const AppTitle = function (props) {

    const context = useContext(Application.Context);

    const renderAddons = () => {

        const factory = context.factory;
        if (!factory)
            return null;

        const addons = (props.subtitleExtra || []).map((c, i) => {
            if (!c.template)
                return null;

            let component = null;
            if (typeof c.template === "string")
                component = factory.load(c.template, { ...c.props, metadata: c, key: i }, { key: i });

            return component;

        }).filter(c => c);

        return addons;
    }

    const subtitle = context.global?.systemSubtitle || props.subtitle;
    // console.log("subtitle from inner", subtitle);

    return props.subtitleExtra?.length ? <>
        <div className="strong app-title" type={'text'}>
            <a className="w100 h100 layout-h center" href={resource(props.siteUrl)}>
                {
                    props.logoSvg ? (
                        <Application.SvgIcon type={props.logoSvg} style={props.logoSvgStyle} />
                    ) : (
                        <img className="logo" src={resource(props.logo)} style={props.logoImageStyle} />
                    )
                }
                <div className="fill mgl10 mgr5 mgb1 title">
                <Lang.Right>{props.title}</Lang.Right>
                
                {subtitle ? <span className="subtitle"><Lang.Right>{subtitle || ""}</Lang.Right><span className='subtitle-extra'>{renderAddons()}</span></span> : null}
                    {/* {props.title}{subtitle ? <span className="subtitle">{subtitle || ""}<span className='subtitle-extra'>{renderAddons()}</span></span> : null} */}
                </div>
            </a>
        </div>
    </> : <>
        <Tooltip title={props.title} placement="bottom">
            <Button className="primary-header-button strong app-title" type={'text'}>
                <a className="w100 layout-h center" href={resource(props.siteUrl)}>
                    {
                        props.logoSvg ? (
                            <Application.SvgIcon type={props.logoSvg} style={props.logoSvgStyle} />
                        ) : (
                            <img className="logo" src={resource(props.logo)} style={props.logoImageStyle} />
                        )
                    }
                   <div className="fill mgl10 mgr5 mgb1 title">
                        <Lang.Right>{props.title}</Lang.Right>
                        {subtitle ? <span className="subtitle">
                            <Lang.Right>{subtitle || ""}</Lang.Right><span className='subtitle-extra'>{renderAddons()}</span>
                        </span> : null}
                        {/* {props.title}{subtitle ? <span className="subtitle">{subtitle || ""}<span className='subtitle-extra'>{renderAddons()}</span></span> : null} */}
                    </div>
                </a>
            </Button>
        </Tooltip>
    </>
}

export default AppTitle;