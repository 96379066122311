import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Tag, Drawer, Tooltip, Typography, message, Pagination, Menu, Dropdown, Space, Badge } from "antd";
import { SyncOutlined, PauseOutlined, DownOutlined } from '@ant-design/icons';

import { Application, taskFrom, notifyFrom, useMessage, useWebSocketMessage } from "@yulintu/freesia-bootstrap";

import { NoData, BaseApi } from "@yulintu/freesia-design";

import "./index.less";

const Addon = function (props) {

    const context = useContext(Application.Context);

    const [flag, setFlag] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    useMessage(data => {
        setFlag({});
    }, "messages.readed");

    useMessage(data => {
        setFlag({});
    }, "messages.all.readed");

    useMessage(data => {
        setFlag({});
    }, "messages.refresh");

    useWebSocketMessage(data => {
        setTimeout(() => {
            setTotalCount(v => v + 1);
        }, 1000);
    }, context.webSocket, "notify");

    useEffect(() => {

        if (!context.global.userInfo?.id || !props.apiUrl)
            return;

        BaseApi.getJson(`${props.apiUrl}/count/unread/by/session/info`, {
            sessionType: "notify",
        }).then(data => {
            setTotalCount(data?.data);
        }).finally(() => {
        });

    }, [flag, props.apiUrl, context.global.userInfo?.id]);

    return <>
        {/* {totalCount ? <span className='unread'>{totalCount}</span> : null} */}
        {totalCount ? <Badge className='mgl10' size='small' count={totalCount} overflowCount={99}></Badge> : null}
    </>;
}

export default Addon;