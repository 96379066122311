import React, { useState, useEffect, useCallback } from 'react';
import _ from "lodash";
import { Form, Menu, Divider } from "antd";

const PropertyItem = function (props) {

    // const [flag, setFlag] = useState(0);
    // const [unWatch, setUnWatch] = useState();

    // useEffect(() => {

    //     console.log("useEffect");
    //     // if (unWatch)
    //     //     unWatch();

    //     const un = props.source.registerPropertyChangedListener((name, valOld, valNew, subName) => {
    //         console.log(name, valNew);
    //         setFlag(Math.random());
    //     });

    //     // setUnWatch(un);

    //     return () => {

    //         console.log(props.source);
    //         // if (unWatch)
    //         //     unWatch();
    //     }

    // }, [props.source]);

    useEffect(() => {
        props.form.setFieldValue(props.source.name, props.source.value);
    }, [props.source.value]);

    return (
        <Form.Item
            {...props}
            label={props.source.pure ? null : props.label}
            labelAlign="left"
            style={{ display: (props.source.visible === false ? 'none' : (props.source.orient === "v" ? "block" : "")) }}
            rules={props.source.rules}>
            {props.children}
        </Form.Item>
    );
}

export default PropertyItem;