import React, { useContext, useState } from 'react';
import { Checkbox } from "antd";

const GreyListItem = function (props) {

    return (
        <div className={"panel bg-grey hoverable layout-h center list-grid-v4-item--list-item"} onClick={e => props.onClick && props.onClick(e)}>
            {props.canCheck !== false ? (
                <div onClick={e => e.stopPropagation()}>
                    <Checkbox checked={props.checked} onChange={e => props.onChecked && props.onChecked(e.target.checked)}>
                    </Checkbox>
                </div>
            ) : null}
            <div className="fill">{props.children}</div>
        </div>
    );
}

export default GreyListItem;