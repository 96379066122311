import React, { useState, useEffect, useCallback } from 'react';
import _ from "lodash";

import PropertySheet from "./PropertySheet";

const PropertyLayout = function (props) {

    const renderEditor = (item) => {

    }

    return (
        <div className={"propertygrid propertygrid-layout fill layout-v vstretch vscroll" + (props.className || "")}  >
            {
                props.catalogs.map(c => {

                    if (c.items.length === 1 && c.items[0].type === "propertygrid") {
                        return renderEditor(c.items[0]);
                    }
                    else {
                        return <PropertySheet
                            key={c.name}
                            source={c}
                            formItemLayout={props.formItemLayout}
                            showDescription={props.showDescription}
                            orient={props.orient}
                            form={props.form} />;
                    }
                })
            }
        </div>
    );
}

export default PropertyLayout;