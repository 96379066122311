// import LogViewer from "./components/LogViewer";

const templates = [
    {
        name: "@system/pages/log-viewer",
        // Component: LogViewer,
        loader: () => import("./components/LogViewer"),
    },
    {
        name: "@system/pages/account-log-viewer",
        // Component: LogViewer,
        loader: () => import("./components/AccountLogViewer"),
    },
];

export default templates;