import HeaderMessageObserver from "./components/HeaderMessageObserver";
import MenuMessageObserver from "./components/MenuMessageObserver";

const templates = [
    {
        name: "@system/pages/message-center",
        // Component: ScheduleDashboard,
        loader: () => import("./components/MessageCenter"),
    },
    {
        name: "@system/pages/message-viewers/timeline",
        // Component: ScheduleDashboard,
        loader: () => import("./components/MessageViewer/Timeline"),
    },
    {
        name: "@system/addons/header-message-observer",
        Component: HeaderMessageObserver,
        pure: true,
    },
    {
        name: "@system/addons/menu-message-observer",
        Component: MenuMessageObserver,
        pure: true,
    },
];

export default templates;