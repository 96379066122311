import React, { useContext, useState, useEffect } from 'react';
import { Button } from 'antd';
import { UserOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';

import { Application } from "@yulintu/freesia-bootstrap";

import "./index.less";

const Addon = function (props) {

    const context = useContext(Application.Context);

    return (
        <Button type='link' className='primary-header-button' style={{ display: "inline-block", padding: "2px 0 0 0", width: 32, height: 32 }}
            onClick={e => { e.preventDefault(); e.stopPropagation(); }}
            onMouseDown={e => { e.preventDefault(); e.stopPropagation(); }}
            onMouseUp={e => { e.preventDefault(); e.stopPropagation(); }}
            onMouseOver={e => { e.preventDefault(); e.stopPropagation(); }}
            onMouseEnter={e => { e.preventDefault(); e.stopPropagation(); }}>
            <SettingOutlined />
        </Button>
    );
}

export default Addon;