import React, { useState, useContext } from 'react';
import { SketchPicker } from 'react-color';
import { Drawer, Select, Switch, Tooltip } from 'antd';
import { CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import ThemeContext from "../../../../../ThemeContext";

import "./index.less";

const Addon = function (props) {

    const themeContext = useContext(ThemeContext);
    const curHeaderStyle = themeContext.themeHeader;
    const curPrimaryColor = themeContext.themeColor;


    const primaryColors = ["rgb(16 84 191)", "#6daa55", "#bd6161", "#d5901b", "rgb(105, 88, 199)", "#4483b9", "#b55d81", "#4b9bad", "#2278d5"];

    const [isCustomPrimaryColor, setIsCustomPrimaryColor] = useState(!primaryColors.includes(curPrimaryColor));
    const [isMaskTransparent, setIsMaskTransparent] = useState(false);

    return <>
        <Drawer className='freedesign theme-settings' title="主题设置" visible={props.visible} onClose={e => props.onClose?.()} width={300} closeIcon={false}
            maskStyle={{ opacity: isMaskTransparent ? 0 : 1 }}>
            <div className='settings-group'>
                <div className='title'>
                    标题栏风格
                </div>
                <div className='layout-h settings-content'>

                    <div className={'style-item dark default' + (curHeaderStyle !== "light" && curHeaderStyle !== "dark" ? " selected" : "")} onClick={e => themeContext.changeThemeHeader("default")}>
                        <div className='header'></div>
                        <div className='content'>
                            {
                                curHeaderStyle !== "light" && curHeaderStyle !== "dark" ? <CheckOutlined /> : null
                            }
                        </div>
                    </div>

                    <div className={'style-item dark' + (curHeaderStyle === "dark" ? " selected" : "")} onClick={e => themeContext.changeThemeHeader("dark")}>
                        <div className='header'></div>
                        <div className='content'>
                            {
                                curHeaderStyle === "dark" ? <CheckOutlined /> : null
                            }
                        </div>
                    </div>

                    <div className={'style-item light' + (curHeaderStyle === "light" ? " selected" : "")} onClick={e => themeContext.changeThemeHeader("light")}>
                        <div className='header'></div>
                        <div className='content'>
                            {
                                curHeaderStyle === "light" ? <CheckOutlined /> : null
                            }
                        </div>
                    </div>

                </div>
            </div>
            <div className='settings-group border'>
                <div className='settings-content'>
                    <div className='settings-item'>
                        <div className='name'>阴影</div>
                        <Select value={themeContext.themeHeaderStyle} onChange={e => themeContext.changeThemeHeaderStyle(e)}>
                            <Select.Option key={"默认"} value={""}>默认</Select.Option>
                            <Select.Option key={"凹陷"} value={"content"}>凹陷</Select.Option>
                            <Select.Option key={"突起"} value={"header"}>突起</Select.Option>
                        </Select>
                    </div>
                </div>
            </div>
            <div className='settings-group'>
                <div className='title'>
                    主题色
                </div>
                <div className='layout-h settings-content'>

                    {
                        primaryColors.map(c => {
                            return (
                                <div className={'color-item' + (curPrimaryColor === c ? " selected" : "")} style={{ backgroundColor: c }} onClick={e => themeContext.changeThemeColor(c)}>
                                    {
                                        curPrimaryColor === c ? <CheckOutlined /> : null
                                    }
                                </div>
                            );
                        })
                    }

                </div>
            </div>
            <div className='settings-group border'>
                <div className='settings-content'>

                    <div className='settings-item'>
                        <div className='name'>自定义主题色</div>
                        <Switch checked={isCustomPrimaryColor} onChange={e => setIsCustomPrimaryColor(e)}></Switch>
                    </div>
                    <div className='color-picker-frame'>
                        <SketchPicker
                            presetColors={[
                                '#3569B7',
                                '#36B46C',
                                '#B83B1D',
                                '#9251BB',
                                "#B55254",
                                '#0072C6',
                                '#57AE55',
                                '#B0517C',
                                '#B059A6',
                                '#53B4A5',
                                '#CA8F08',
                                '#35A6B8',
                                '#B846A0',
                                '#7A58C7',
                                '#90B936',
                                '#3699B9',
                                '#D79110',
                                '#17B53B',
                            ]}
                            color={curPrimaryColor}
                            onChange={({ hex }) => {
                                themeContext.changeThemeColor(hex);
                            }}
                        />
                        {
                            !isCustomPrimaryColor ? (
                                <div className='abs-0 w100 h100 mask'></div>

                            ) : null
                        }
                    </div>


                </div>
            </div>
            <div className='settings-group'>
                <div className='settings-content'>
                    <div className='title'>
                        其他
                    </div>
                    <div className='settings-item'>
                        <div className='name layout-h center'><div className='mgr5'>遮罩层透明</div><Tooltip title={"该选项只会控制“主题设置”的遮罩层，以便在更换主题色时预览效果"}><QuestionCircleOutlined className='question' /></Tooltip></div>

                        <Switch size="small" checked={isMaskTransparent} onChange={e => setIsMaskTransparent(e)}></Switch>
                    </div>
                </div>
            </div>
        </Drawer>
    </>
}

export default Addon;