import moment from 'moment';
import React, { useContext, useState, useEffect } from 'react';
import { Progress, Button, Input, Tabs, Spin, message, Tag } from "antd";
import { InfoCircleOutlined, ClusterOutlined, SyncOutlined, CloseCircleOutlined, WarningOutlined, UnorderedListOutlined, PauseCircleOutlined, CheckCircleOutlined, TableOutlined } from '@ant-design/icons';

import { Application, DateTimeUtils, taskFrom } from "@yulintu/freesia-bootstrap";
import FullScreenModal from "../../../../components/FullScreenModal";
import BaseApi from "../../../../../utils/apis/BaseApi";
import Summary from "./Summary";
import Logs from "./Logs";
import Results from "./Results";

import "./index.less";

export default function Modal(props) {

    const context = useContext(Application.Context);

    const [flag, setFlag] = useState(0);
    const [intervalId, setIntervalId] = useState();
    const [selectedTab, setSelectedTab] = useState("1");

    const [task, setTask] = useState(props.task);
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        setTask(props.task);
        setSelectedTab("1");
    }, [props.task]);

    useEffect(() => {

        if (props.visible && !intervalId) {
            const id = setInterval(() => {
                setFlag(Math.random());
            }, 3000);

            setIntervalId(id);
        }
        else if (!props.visible && intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }

    }, [props.visible, intervalId]);

    useEffect(() => {

        if (!context.global.userInfo?.id || !props.task?.info?.id || !props.scheduleUrl) {
            setTask(null);
            return;
        }

        BaseApi.getJson(`${props.scheduleUrl}/job`, { jobId: `${props.task?.info?.id}` })
            .then(data => {
                setTask(taskFrom(data?.data));
            }).finally(() => {
            });

    }, [flag, props.scheduleUrl, context.global.userInfo?.id, props.task]);

    useEffect(() => {

        if ((!context.global.userInfo?.id || !props.task?.info?.id) && !logs.length) {
            return;
        }
        if ((!context.global.userInfo?.id || !props.task?.info?.id) && logs.length) {
            setLogs([]);
            return;
        }

        if (!props.scheduleUrl) {
            setLogs([]);
            return;
        }

        BaseApi.getJson(`${props.scheduleUrl}/job/log/list/by/job`, { jobId: `${props.task?.info?.id}`, order: 'DESC', size: 500 })
            .then(data => {

                const currentLogs = (data?.data || []).reverse();
                const currentLastId = currentLogs.length ? currentLogs[currentLogs.length - 1].id : null;
                const lastId = logs.length ? logs[logs.length - 1].id : null;

                if (lastId !== currentLastId)
                    setLogs(currentLogs);

            }).finally(() => {
            });

    }, [flag, props.scheduleUrl, context.global.userInfo?.id, props.task]);

    const onOk = e => {
    }

    const onCancel = e => {
        props.onCancel && props.onCancel();
    }

    const onStop = () => {
        BaseApi.getJson(`${props.scheduleUrl}/job/stop/try`, { jobId: task.info.id })
            .then(data => {
                message.success("停止请求已成功发送，任务开始尝试停止");
            }).finally(() => {
            });
    }

    const getStatus = item => {

        if (item.state !== "ended")
            return "active";
        if (item.hasTerminated)
            return "exception";
        if (item.hasError)
            return "exception";
        if (item.hasStopped)
            return "normal";
        if (item.hasWarn)
            return "success";

        return "success";
    }

    const getTagText = item => {

        if (item.state !== "ended")
            return "进行中";
        if (item.hasTerminated)
            return "有错误";
        if (item.hasError)
            return "有错误";
        if (item.hasWarn)
            return "有警告";
        if (item.hasStopped)
            return "被停止";

        return "已完成";
    }

    const getTagColor = item => {

        if (item.hasTerminated)
            return "error";
        if (item.hasError)
            return "error";
        if (item.hasWarn)
            return "warning";
        if (item.state !== "ended")
            return "processing";
        if (item.hasStopped)
            return "purple";

        return "success";
    }

    const getTagIcon = item => {

        if (item.state !== "ended")
            return <SyncOutlined spin />;
        if (item.hasTerminated)
            return <CloseCircleOutlined />;
        if (item.hasError)
            return <CloseCircleOutlined />;
        if (item.hasWarn)
            return <WarningOutlined />;
        if (item.hasStopped)
            return <PauseCircleOutlined />;

        return <CheckCircleOutlined />;
    }

    const getProgressColor = item => {

        if (item.hasTerminated)
            return "#c5474a";
        if (item.hasError)
            return "#c5474a";
        if (item.hasWarn)
            return "#faad14";
        if (item.hasStopped)
            return undefined;

        return undefined;
    }

    const getPercent = c => {

        const state = getStatus(c);
        const percent = c?.currentPercent?.toFixed(0) || 0;

        if (state === "active")
            return Math.min(percent, 99);
        else if (state === "success")
            return 100;
        else
            return percent;
    }

    const c = task?.info || {};
    const status = c ? getStatus(c) : null;
    const duration = status === "active" || !c?.durationTime ? DateTimeUtils.prettyPeroidByNow(moment(c?.beginTime)) : DateTimeUtils.prettyPeroid(moment.duration(c?.durationTime));
    const ResultViewer = task?.getResultViewer?.() || Results;

    return (
        <FullScreenModal
            className="modal-backstage white modal--task-job-detail"
            title={(
                <div className="task-job-title">
                    <Application.SystemSvgIcon type={task?.getSvgIcon()}></Application.SystemSvgIcon>
                    {/* <Application.SvgIcon type={"shezhi"}></Application.SvgIcon> */}
                    <div className='name-col'>
                        <div className="name">{`任务详情 - ${task?.name}`}</div>
                        <Progress className={c.hasWarn ? "lg has-warn" : "lg"} percent={getPercent(c)} status={status} strokeColor={getProgressColor(c)} strokeWidth={12}></Progress>
                    </div>
                    <div className='info'>
                        <div className='name-row'>
                            {c?.hasStopped ? null : <Tag className='mgl5' icon={getTagIcon(c || {})} color={getTagColor(c || {})}>{getTagText(c || {})}</Tag>}
                            {c?.hasStopped ? <Tag className='mgl5' color={"purple"} icon={<PauseCircleOutlined />}>{"被停止"}</Tag> : null}
                        </div>
                        <div className='time'>
                            <div className='key'>{status === "active" ? "开始于" : "完成于"}</div>
                            <div className='value' title={status === "active" ? c?.beginTime : c?.endTime}>{DateTimeUtils.prettyDurationShortByNow(moment(status === "active" ? c?.beginTime : c?.endTime))}</div>
                            <div className='key mgl5'>{status === "active" ? "已进行" : "耗时"}</div>
                            <div className='value duration text-nowrap text-ellipsis' title={duration}>{duration}</div>
                        </div>
                    </div>
                    {status === "active" ? <Button className='stop' onClick={e => onStop(c)} title={"尝试停止任务"}>{"停止"}</Button> : null}
                </div>
            )}
            centered
            footer={null}
            maskClosable={false}
            destroyOnClose={true}
            visible={props.visible}
            width="100%"
            style={{ maxWidth: '100%' }}
            onCancel={onCancel}
            onOk={onOk}
            afterClose={props.afterClose}
        >
            <div className="layout-v h100">
                <div className="fill h0">
                    <Tabs className="gradient-ink-bar full h100" tabBarGutter={0} activeKey={selectedTab} onChange={e => setSelectedTab(e)}>
                        <Tabs.TabPane key="0" className="card shadow12" tab={<div><UnorderedListOutlined />{"摘要"}</div>}>
                            <Summary task={task} />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="1" className="card shadow12" tab={<div><InfoCircleOutlined />{"日志"}</div>}>
                            <Logs task={task} logs={logs} />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="2" className="card shadow12" tab={<div><ClusterOutlined />{"结果"}</div>} disabled={status === "active"}>
                            <ResultViewer task={task} ></ResultViewer>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </FullScreenModal>
    );
}