import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Tag, Drawer, Tooltip, Typography, message, Pagination, Menu, Dropdown, Space, Badge, notification } from "antd";
import { SyncOutlined, PauseOutlined, DownOutlined } from '@ant-design/icons';

import { Application, taskFrom, notifyFrom, useMessage, useWebSocketMessage } from "@yulintu/freesia-bootstrap";

import { NoData, BaseApi } from "@yulintu/freesia-design";

import "./index.less";

const Addon = function (props) {

    const context = useContext(Application.Context);
    const { setGlobal } = context;

    const history = useHistory();

    const [visible, setVisible] = useState(false);

    const [flag, setFlag] = useState(0);
    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);

    const [searchKey, setSearchKey] = useState("");

    useMessage(data => {
        if (data?.sender !== "message-observer")
            setVisible(false);
    }, "drawer.shown");

    useMessage(data => {
        if (data?.sender !== "message-observer")
            setFlag({});
    }, "messages.readed");

    useMessage(data => {
        if (data?.sender !== "message-observer")
            setFlag({});
    }, "messages.all.readed");

    useMessage(data => {
        if (data?.sender !== "message-observer")
            setFlag({});
    }, "messages.refresh");

    useWebSocketMessage(data => {
        console.log("收到消息", data);
        setTimeout(() => {
            setTotalCount(v => v + 1);
            setItems(v => [data, ...v]);
            doNotify(data);
        }, 1000);
    }, context.webSocket, "notify");

    useEffect(() => {

        setGlobal({
            showMessageObserver: () => {
                setVisible(true);
            },
            hideMessageObserver: () => {
                setVisible(false);
            },
        });


    }, [setGlobal]);

    useEffect(() => {

        if (!context.global.userInfo?.id || !props.apiUrl)
            return;

        BaseApi.getJson(`${props.apiUrl}/count/unread/by/session/info`, {
            sessionType: "notify",
        }).then(data => {
            setTotalCount(data?.data);
        }).finally(() => {
        });

    }, [flag, props.apiUrl, context.global.userInfo?.id]);

    useEffect(() => {

        if (!context.global.userInfo?.id || !props.apiUrl)
            return;

        BaseApi.getJson(`${props.apiUrl}/list/unread/by/session/info`, {
            sessionType: "notify",
            page: pageIndex,
            size: pageSize
        }).then(data => {
            setItems(data?.data);
        }).finally(() => {
        });

    }, [flag, pageIndex, pageSize, totalCount, props.apiUrl, context.global.userInfo?.id]);

    const doNotify = c => {

        if (c.state === "Error")
            notification.error({
                className: "error-background",
                placement: "topRight",
                message: '新消息',
                description: c.title || c.body,
            });
        else if (c.state === "Success")
            notification.success({
                className: "success-background",
                placement: "topRight",
                message: '新消息',
                description: c.title || c.body,
            });
        else if (c.state === "Info")
            notification.info({
                className: "info-background",
                placement: "topRight",
                message: '新消息',
                description: c.title || c.body,
            });
        else if (c.state === "Warn")
            notification.warn({
                className: "warn-background",
                placement: "topRight",
                message: '新消息',
                description: c.title || c.body,
            });
    }

    const onOpen = c => {

        const meta = notifyFrom(c);
        const success = meta.onOpen?.();
        if (!success) {
            message.warn("该消息未注册打开行为，无法打开");
        }

        if (c.handled)
            return;

        BaseApi.getJson(`${props.apiUrl}/mark/as/read`, {
            messageId: c.id,
        }).then(data => {
            c.handled = 1;
            Application.EventEmitter.emit("messages.readed", { sender: "message-observer", data: c });
            setFlag({});
        }).finally(() => {
        });
    }

    const markAllAsRead = () => {

        if (!context.global.userInfo?.id || !props.apiUrl || !items.length) {
            return;
        }

        BaseApi.getJson(`${props.apiUrl}/mark/all/as/read`, {
            sessionType: "notify",
        }).then(data => {
            Application.EventEmitter.emit("messages.all.readed", { sender: "message-observer" });
            setFlag({});
        }).finally(() => {
        });
    }

    const onOpenMessageCenter = () => {
        window.open(props.messageCenterUrl, "_blank");
        // history.push({ pathname: props.messageCenterUrl });
        // context.global.hideMessageObserver?.();
    }

    const getStatus = item => {

        if (item.state === "Error")
            return "exception";
        if (item.state === "Success")
            return "success";

        return "normal";
    }

    const getTagText = item => {

        if (item.state === "Error")
            return "错误";
        if (item.state === "Warn")
            return "警告";
        if (item.state === "Success")
            return "成功";

        return "信息";
    }

    const getTagColor = item => {

        if (item.state === "Error")
            return "error";
        if (item.state === "Warn")
            return "warning";
        if (item.state === "Success")
            return "success";

        return "processing";
    }

    const getIconColor = item => {

        if (item.state === "Error")
            return `var(--ant4-error-color)`;
        if (item.state === "Warn")
            return `var(--ant4-warning-color)`;
        if (item.state === "Success")
            return `var(--ant4-success-color)`;

        return `var(--ant4-info-color)`;
    }

    const renderContent = () => {

        return items.length ? <>
            <div className='fill h0 vscroll'>
                {
                    items.map((c, i) => {

                        const meta = notifyFrom(c);
                        let svg = meta.getSvgIcon?.();
                        if (!svg)
                            svg = "shejiaoxinxi";

                        return (
                            <div key={c.id} className='observer-item message-item'>
                                <div className='status-text'>
                                    <Application.SystemSvgIcon type={svg} style={{ fontSize: 48, color: getIconColor(c) }} />
                                    <div className='info'>
                                        <div className='name-row' title={c.title || c.body}>
                                            <a className='text-ellipsis text-nowrap name' onClick={e => onOpen(c)}>{c.title || c.body}</a>
                                            <div className={'state ' + (getTagColor(c))}></div>
                                            <div color={getTagColor(c)} style={{ marginRight: 0, marginTop: 0, color: getIconColor(c) }}>{getTagText(c)}</div>
                                        </div>
                                        <div className='state-row'>

                                        </div>
                                        <Typography.Paragraph className="description text-second text-wrap" title={c.body} ellipsis={{ rows: 2, expandable: false }}>
                                            {c.body || "该通知没有正文"}
                                        </Typography.Paragraph>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <Pagination
                size="small"
                simple
                total={totalCount}
                pageSize={pageSize}
                current={pageIndex}
                onChange={(current, size) => { setPageIndex(current); setPageSize(size); }}
            />
        </> : <NoData title="暂无未读通知"></NoData>
    }

    const menu = (
        <Menu>
            <Menu.Item key={"markallasread"} onClick={e => markAllAsRead()}>全部设为已读</Menu.Item>
            <Menu.Divider >刷新</Menu.Divider>
            <Menu.Item key={"refresh"} onClick={e => { Application.EventEmitter.emit("messages.refresh", { sender: "message-observer" }); setFlag({}); }}>刷新</Menu.Item>
        </Menu>
    );

    return <>
        <Tooltip title="通知" placement='bottom'>
            <Button
                className={"primary-header-button message-observer "}
                type={'text'}
                onClick={e => { Application.EventEmitter.emit(`drawer.${visible ? "hidden" : "shown"}`, { sender: "message-observer" }); setVisible(!visible); }}
                icon={
                    <Application.SystemSvgIcon style={{
                        fontSize: 28,
                        height: 28
                    }} type={"Alert-1"}>
                    </Application.SystemSvgIcon>}>
                {totalCount > 0 ? <Badge count={totalCount} overflowCount={99}></Badge> : null}
            </Button>
        </Tooltip>
        {
            context.global.contentRef.current ? (
                <Drawer
                    className='message-observer-drawer'
                    title={null}
                    width={310}
                    placement="right"
                    closable={false}
                    maskClosable={true}
                    visible={visible}
                    style={{ position: 'absolute' }}
                    getContainer={() => {
                        return context.global.contentRef.current;
                    }}
                    onClose={e => setVisible(false)}
                >
                    <div className='layout-v observer-panel'>
                        <div className='layout-h center header'>
                            <span className='title'>通知</span>
                            {totalCount > 0 ? <Badge className='mgl5 mgt2 mgr10' title='未读消息数量' count={totalCount} overflowCount={99}></Badge> : null}
                            <div className='fill'></div>
                            <a className='mgr10 mgt2' onClick={e => onOpenMessageCenter()}>查看全部通知</a>
                            <Dropdown overlay={menu} trigger={['click']}>
                                <a onClick={e => e.preventDefault()} className="mgt2">
                                    <Space>
                                        更多
                                        <DownOutlined />
                                    </Space>
                                </a>
                            </Dropdown>
                        </div>
                        {renderContent()}
                    </div>
                </Drawer>
            ) : null}
    </>;
}

export default Addon;