import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Switch } from 'react-router-dom';
import { Breadcrumb, Row, Col } from "antd";

import { Application, Bootstrap } from "@yulintu/freesia-bootstrap";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import "./index.less";

const OneRowGrid = function (props) {

    const context = useContext(Application.Context);

    const [busy, setBusy] = useState(true);
    const [manifests, setManifests] = useState([]);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setBusy(true);
        Bootstrap.resolve(props.metadata.children, props.metadata).then(data => {
            setManifests(data);
        }).finally(() => {
            setBusy(false);
        });
    }, [props.metadata]);

    useEffect(() => {
        setBusy(true);
        Bootstrap.resolve(props.metadata.items, props.metadata).then(data => {
            setItems(data);
        }).finally(() => {
            setBusy(false);
        });
    }, [props.metadata]);

    useEffect(() => {

        if (manifests && manifests.length)
            Bootstrap.resetPermission(manifests, { userInfo: context.global.userInfo });

    }, [props.metadata, manifests, context.global.userInfo]);

    const setGlobal = context.setGlobal;
    const onLoginSuccess = useCallback((userInfo) => {
        setGlobal({ userInfo });
    }, [setGlobal]);


    const renderContent = () => {

        return (
            <div className={"freedesign layout-one-row-grid " + (props.metadata.props?.className || "")} style={{ paddingTop: props.showBreadcrumb ? 20 : 0, ...props.containerStyle }}>
                {props.showBreadcrumb ? <Breadcrumb {...props.metadata?.breadcrumb} /> : null}
                <Row gutter={24} style={{ paddingTop: 24, marginTop: props.showBreadcrumb ? -4 : 0 }}>
                    {
                        (items || []).map((c, i) => {

                            let component = null;
                            if (typeof c.template === "string")
                                component = context?.factory?.load(c.template, { ...c.props, metadata: c }, { pure: true });

                            return component ? (
                                <Col key={i + ""} style={{ marginBottom: 24, }}  {...c.colProps}>
                                    <div className={"grid-item " + (props.itemStyle || "card")}>
                                        {component}
                                    </div>
                                </Col>
                            ) : null;
                        }).filter(c => c)
                    }
                </Row>

                <div className='layout-grid-children'>
                    <Switch>
                        {
                            Bootstrap.routes(context.factory, manifests, props.metadata, {
                                config: context.config,
                                onLoginSuccess: onLoginSuccess
                            })
                        }
                    </Switch>
                </div>

            </div>
        );
    }

    return busy ? <>
        <div></div>
    </> : renderContent();
}

export default OneRowGrid;