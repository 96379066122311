import React, { useState, useEffect, useCallback } from 'react';
import { Input } from "antd";

const ReadonlyEditor = function (props) {

    // const [flag, setFlag] = useState();

    // useEffect(() => {
    //     setFlag(Math.random());
    // }, [props.source.value]);

    return (
        <Input disabled={true} value={props.source.value} title={props.source.value} />
    );
}

export default ReadonlyEditor;