import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Steps, Tabs } from 'antd';
import './index.less';

const StepWizzard = React.forwardRef(function (props, ref) {

    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [stepsContext, setStepsContext] = useState({ results: {} });

    const combineResults = (index) => {
        const results = stepsContext.results || {};
        let val = {};
        for (let i = 0; i <= index; i++) {
            val = {
                ...val,
                ...results[i]
            }
        }
        return val;
    }

    useImperativeHandle(ref, () => ({

        getCurrentStepIndex: () => currentStepIndex,

        pre: () => {
            const index = currentStepIndex - 1;
            setCurrentStepIndex(index);
            setTimeout(() => {

                const result = combineResults(index - 1);
                setStepsContext({
                    ...stepsContext,
                    result
                });

                props.onStepBack?.({ step: index, result });
            }, 0);
        },

        next: () => {

            const callback = stepsContext[currentStepIndex];
            return callback?.().onConfirm?.().then(e => {

                stepsContext.results[currentStepIndex] = e;
                const result = combineResults(currentStepIndex);

                setStepsContext({
                    ...stepsContext,
                    result
                });

                const total = props.children.length ? props.children.length : (props.children ? 1 : 0);
                if (currentStepIndex >= total - 1) {
                    props.onStepComplete?.({ step: currentStepIndex, result, });
                    props.onComplete?.({ result });
                }
                else {
                    props.onStepComplete?.({ step: currentStepIndex, result, });
                    setCurrentStepIndex(currentStepIndex + 1);
                }
            })
        }
    }));

    const renderSteps = () => {
        if (props.children.map) {
            let children = props.children.map((child, i) => {
                return (
                    <Steps.Step title={child.props.title} description={child.props.description} />
                );
            });
            return children;
        } else {
            if (props.children) {
                return (
                    <Steps.Step title={props.children.props.title} description={props.children.props.description} />
                );
            }
        }
    }

    const renderChildren = () => {
        if (props.children.map) {
            let children = props.children.map((child, i) => {
                return (
                    <Tabs.TabPane key={i + ""}>
                        {
                            React.cloneElement(child, {
                                stepsContext: stepsContext,
                                stepIndex: i,
                                defaultValue: props.defaultValue,
                                value: stepsContext.result,
                            })
                        }
                    </Tabs.TabPane>
                );
            });
            return children;
        } else {
            if (props.children) {
                return (
                    <Tabs.TabPane key={"0"}>
                        {
                            React.cloneElement(props.children, {
                                stepsContext: stepsContext,
                                stepIndex: 0,
                                defaultValue: props.defaultValue,
                                value: stepsContext.result,
                            })
                        }
                    </Tabs.TabPane>
                );
            }
        }
    }

    return (
        <div className={'step-wizzard ' + (props.className || "")} style={props.style}>
            <div className='steps'>
                <Steps current={currentStepIndex}>
                    {renderSteps()}
                </Steps>
            </div>
            <div className={'card tabs ' + (props.cardStyle || "shadow12")}>
                <Tabs className="gradient-ink-bar full h100" activeKey={currentStepIndex + ""} >
                    {renderChildren()}
                </Tabs>
            </div>
        </div>
    );
});

function useStep(callback, props) {

    if (!props.stepsContext)
        return;

    const context = props.stepsContext;
    context[props.stepIndex] = callback;

    return [];
}

StepWizzard.useStep = useStep;
export default StepWizzard;
