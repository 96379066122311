import React, { useState } from 'react';
import { Affix, Anchor } from 'antd';

import "./index.less";

const DocumentContainer = function (props) {

    const [container, setContainer] = useState(null);

    const extractTitles = (children, arr) => {

        if (!(children instanceof Array))
            return;

        children.forEach(c => {

            if (!c)
                return;
                
            if (c?.props?.id) {
                const level = c.props?.level || 1;
                arr.push({
                    id: c.props.id.replace(/ /g, "_"),
                    name: c.props.id,
                    level: level,
                });
            }

            if (c instanceof Array)
                extractTitles(c, arr);
            else
                extractTitles(c.props?.children, arr);
        })
    }

    const arr = [];
    extractTitles(props.children, arr);

    const renderLinks = (arr, root) => {
        return arr.map((c, i) => {
            if (c.level !== root.level + 1)
                return;

            return (
                <Anchor.Link key={c.id} href={`#${c.id}`} title={c.name}>{renderLinks(arr.slice(i + 1), c)}</Anchor.Link>
            );
        }).filter(c => c);
    }

    return <>
        <div className="freesia-design docs-frame" ref={setContainer}>
            <div className="docs-container">
                {
                    container ? (
                        <Affix className="docs-affix" target={() => container}>
                            <Anchor getContainer={() => container} targetOffset={20}>
                                {
                                    renderLinks(arr, { level: 0 })
                                }
                            </Anchor>
                        </Affix>
                    ) : undefined
                }

                {
                    props.children
                }

            </div>
        </div>
    </>
}

export default DocumentContainer;