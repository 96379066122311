import React, { useState, useEffect, useCallback } from 'react';
import _ from "lodash";

import PropertyLayout from "./PropertyLayout";

const PropertyGallery = function (props) {

    const [flag, setFlag] = useState(0);
    const [sourceGalleries, setSourceGalleries] = useState([]);

    useEffect(() => {

        if (!props.source) {
            setSourceGalleries([]);
            return;
        }

        const item = props.source;

        const mapCatalogToGallery = {};
        const properties = [];
        for (const key in item._) {
            const value = item._[key];
            if (value.enabled === undefined || value.enabled) {
                value.name = key;
                value._sys.form = props.form;
                properties.push(value);

                if (value.gallery && value.catalog && !mapCatalogToGallery[value.catalog])
                    mapCatalogToGallery[value.catalog] = value.gallery;
                else if (value.catalog && !value.gallery && mapCatalogToGallery[value.catalog])
                    value.gallery = mapCatalogToGallery[value.catalog];
            }
        }

        let group = _.groupBy(properties, c => c.gallery || "常规");
        let galleries = [];
        for (const name in group) {
            const items = group[name];
            galleries.push({ name, items });
        }

        galleries = _.sortBy(galleries, c => {
            let max = _.minBy(c.items, d => d.galleryIndex !== undefined ? d.galleryIndex : 999999999);
            return max.galleryIndex || 0;
        });

        galleries.forEach(g => {

            group = _.groupBy(g.items, c => c.catalog);
            let catalogs = [];
            for (const name in group) {
                const items = group[name];
                catalogs.push({ name, items });
            }

            catalogs = _.sortBy(catalogs, c => {
                let max = _.minBy(c.items, d => d.catalogIndex !== undefined ? d.catalogIndex : 999999999);
                return max.catalogIndex || 0;
            });

            g.catalogs = catalogs;
        });

        setSourceGalleries(galleries);

    }, [props.source, props.form]);

    useEffect(() => {

        if (!props.source)
            return;

        const un = props.source.registerPropertyChangedListener((name, valOld, valNew, subName) => {
            setFlag(Math.random());
        });

        return () => {
            un();
        }

    }, [props.source]);

    return (
        <div className={"propertygrid-gallery fill layout-v vstretch" + (props.className || "")}  >
            {sourceGalleries.length ? <PropertyLayout
                catalogs={sourceGalleries[0].catalogs}
                formItemLayout={props.formItemLayout}
                showDescription={props.showDescription}
                orient={props.orient}
                form={props.form} /> : null}
        </div>
    );
}

export default PropertyGallery;