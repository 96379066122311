import React, { useState, useEffect, } from 'react';
import { Form } from "antd";

import PropertyGallery from "./PropertyGallery";
import PropertyGridUtils from "./PropertyGridUtils";

import { } from "./Editors/TipsEditor";
import { } from "./Editors/StringEditor";
import { } from "./Editors/PasswordEditor";
import { } from "./Editors/IntegerEditor";
import { } from "./Editors/CheckCardEditor";
import { } from "./Editors/DateEditor";

import "./index.less";

const PropertyGrid = function (props) {

    const [activeTab, setActiveTab] = useState("primary");

    useEffect(() => {
        const form = props.form;
        if (!form)
            return;

        const source = props.source;
        if (!source)
            return;

        form.setFieldsValue(source);

    }, [props.form, props.source]);

    const formItemLayout = props.orient === "v" ? {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    } : {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12 },
        },
    };

    return (
        <div className={"newpropertygrid-container layout-v " + (props.orient === "v" ? "orient-v " : "") + (props.className || "")} style={props.style}>
            <Form layout={props.orient === "v" ? "vertical" : "horizontal"} form={props.form} {...(props.formItemLayout || formItemLayout)} colon={props.showColon} requiredMark={props.showRequiredMark}>
                <PropertyGallery
                    source={props.source}
                    formItemLayout={props.formItemLayout || formItemLayout}
                    showDescription={props.showDescription}
                    orient={props.orient}
                    form={props.form}
                />
            </Form>
        </div>
    );
}

PropertyGrid.register = (name, uiGetter) => {
    PropertyGridUtils.register(name, uiGetter);
}

export default PropertyGrid;
