import React, { useContext, useState, useEffect } from 'react';
import { Dropdown, Menu, Avatar } from 'antd';
import { UserOutlined, SettingOutlined, LogoutOutlined, LoginOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import sso from 'freesia-sso';
import Sso from '@yulintu/freesia-react-component-sso';

import { Application } from "@yulintu/freesia-bootstrap";

import "./index.less";

const Addon = function (props) {

    const context = useContext(Application.Context);

    const { userInfo } = context.global || {};
    const nickname = (userInfo?.realname || userInfo?.nickname || userInfo?.name || "").trim();
    const username = (userInfo?.name || "").trim();
    const userid = (userInfo?.id || "").trim();

    const [userPortraitError, setUserPortraitError] = useState(false);
    const [userPortrait, setUserPortrait] = useState();

    useEffect(() => {
        setUserPortrait(!userid ? undefined : `${context.config.ssoSiteUrl}/account/member/${userid}/portrait`);
    }, [userid, context.config.ssoSiteUrl]);

    const menu = (
        <Menu>
            <Menu.Item key={"用户中心"} icon={<SettingOutlined />} onClick={e => {
                let ssoSiteUrl = context?.config?.ssoSiteUrl;
                if (ssoSiteUrl) {
                    window.open(ssoSiteUrl + '/center', "_blank");
                }
            }}>
                用户中心
            </Menu.Item>
            <Menu.Item key={"客服中心"} icon={<CustomerServiceOutlined />} onClick={e => {
                let ssoSiteUrl = context?.config?.ssoSiteUrl;
                if (ssoSiteUrl) {
                    window.open(ssoSiteUrl + '/center/workorder', "_blank");
                }
            }}>
                客服中心
            </Menu.Item>
            {
                (props.metadata?.props?.extra || []).length ? <Menu.Divider key={"d0"} /> : null
            }
            {
                (props.metadata?.props?.extra || []).map((c, i) => {

                    if (!c.template)
                        return null;

                    let component = null;
                    if (typeof c.template === "string")
                        component = context.factory.load(c.template, { ...c.props, metadata: c, key: c.key || (i + "") }, { key: c.key || (i + "") });

                    if (c.divider)
                        return [<Menu.Divider key={"d1_" + i} />, component];
                    else
                        return component;
                })
            }
            <Menu.Divider key={"d2"} />
            {
                userid ?
                    <Menu.Item key={"退出"} icon={<LogoutOutlined />} onClick={e => sso.logout()}>退出</Menu.Item> :
                    <Menu.Item key={"去登录"} icon={<LoginOutlined />} onClick={e => {
                        Sso.execute(null, e => {
                            context.webSocket?.send("identify", e.data?.name);
                            context.setGlobal({ userInfo: e.data })
                        })
                    }}>去登录</Menu.Item>
            }
        </Menu>
    );

    return (
        <Dropdown className="primary-header-dropdown user-menu" overlay={menu} placement="bottomLeft" overlayClassName="primary-header-dropdown-popup-menu">
            <a className="ant-dropdown-link layout-h center" onClick={e => {
                if (!userid)
                    Sso.execute(null, e => {
                        context.webSocket?.send("identify", e.data?.name);
                        context.setGlobal({ userInfo: e.data })
                    })
            }}>
                <div className='user-avatar'>
                    {
                        !userPortrait || userPortraitError ? (
                            <Avatar className={nickname && "login"} size={"large"} icon={nickname ? undefined : <UserOutlined />}>
                                {username ? username.substring(0, 1).toUpperCase() : ""}
                            </Avatar>
                        ) : null
                    }
                    {
                        !userPortraitError && userid ? (
                            <img
                                className='user-portrait'
                                src={userPortrait}
                                onError={e => { setUserPortraitError(true); setUserPortrait(null) }}
                            />
                        ) : null
                    }
                </div>

                <div className="name">{nickname || "未登录"}</div>
            </a>
        </Dropdown>
    );
}

export default Addon;