import moment from "moment";
import React, { useState, useEffect, useContext, useMemo } from 'react';
import Highlight from 'react-highlight';
import { stripIndent } from "common-tags";
import { Button, Tag, Form, Spin, message, Table, Select, Modal, Tooltip, Input, Divider } from 'antd';
import { ExclamationCircleOutlined, SearchOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { Application } from '@yulintu/freesia-bootstrap';

import "highlight.js/styles/atom-one-light.css";
import './index.less';

export default function Results(props) {

    const Json = useMemo(() => {

        let txt = props.task?.info.results;
        try {
            if (txt)
                txt = JSON.stringify(JSON.parse(txt), null, "    ");
        }
        catch {

        }

        return txt ? (
            <Highlight className={"json"}>
                {stripIndent(txt)}
            </Highlight>
        ) : (
            <div>没有结果可以显示</div>
        );
    }, [props.task?.info.results]);

    return (
        <Spin wrapperClassName="full" spinning={false} size="large">
            <div className="h100 layout-v card-settings results">
                <div className="h100 vscroll hscroll">
                    {Json}
                </div>
            </div>
        </Spin>
    );
}