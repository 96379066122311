import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import AppGallerySection from "./AppGallerySection";

import NoData from "../../../components/NoData";

import { Application, Bootstrap } from "@yulintu/freesia-bootstrap";

export default function AppGalleryList(props) {

    const context = useContext(Application.Context);
    const [apps, setApps] = useState([]);
    const [sections, setSections] = useState([]);

    useEffect(() => {

        if (props.apps && props.apps.length)
            Bootstrap.resetPermission(props.apps, { userInfo: context.global.userInfo });

        setApps([...props.apps]);

    }, [props.apps, context.global.userInfo]);

    useEffect(() => {

        let items = apps.filter(c => c.enabled !== false && (c.title?.includes(props.searchKey) || c.description?.includes(props.searchKey)));
        items = _.sortBy(items, c => c.index || 999999999);

        let group = _.groupBy(items, c => c.catalog || "常规");
        let galleries = [];
        for (const name in group) {
            const items = group[name];
            galleries.push({ name, items });
        }

        galleries = _.sortBy(galleries, c => {
            let max = _.minBy(c.items, d => d.catalogIndex !== undefined ? d.catalogIndex : 999999999);
            return max.catalogIndex || 999999999;
        });

        setSections(galleries);

    }, [apps, props.searchKey]);

    return (
        sections.length === 1 ?
            <AppGallerySection
                apps={sections[0].items}
                title={false}
                onClick={e => props.onClick?.(e)}
            >
            </AppGallerySection> :
            sections.map(c => {
                return (
                    <AppGallerySection
                        key={c.name}
                        apps={c.items}
                        title={c.name}
                        onClick={e => props.onClick?.(e)}
                    >
                    </AppGallerySection>
                );
            })

    );
}