import React, { useState, useEffect, useCallback } from 'react';
import { DatePicker } from "antd";

import PropertyGridUtils from "../PropertyGridUtils";

const DateEditor = function (props) {

    // const [flag, setFlag] = useState();

    // useEffect(() => {
    //     setFlag(Math.random());
    // }, [props.source.value]);

    const onChange = (date, dateString) => {
        props.source.value = date;
        props.onChange?.(date);
        // props.onChange?.(e.target.value);
        // setFlag(Math.random());
    }

    return <DatePicker
        placeholder={props.source.placeholder || ""}
        value={props.source.value}
        onChange={e => onChange(e)} />
}

export default DateEditor;

PropertyGridUtils.register("date", (source) => <DateEditor source={source} />);