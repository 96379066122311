import React, { useState } from 'react';
import { Table } from 'antd';

import "./index.less";

export default function PropertyTable(props) {

    const [columns] = useState([
        {
            title: '名称',
            dataIndex: 'key',
            key: 'key',
            width: props.keyWidth || undefined,
            render: (text, record) => {
                return <span className='key'>{text}</span>;
            },
        },
        // {
        //     title: '间隙',
        //     dataIndex: 'key',
        //     key: 'space',
        //     width: 3,
        //     render: (text, record) => {
        //         return null;
        //     },
        // },
        {
            title: '值',
            dataIndex: 'value',
            key: 'value',
            render: (text, record) => {
                return <span className='value'>{text}</span>;
            }
        }
    ]);

    return <>
        <Table
            {...props}
            className={'property-table ' + (props.className || "")}
            showHeader={false}
            pagination={false}
            columns={columns}
            dataSource={props.dataSource}
            rowClassName={(record, index) => (index % 2) === 0 ? "row-al" : ""}
        >
        </Table>
    </>;
}