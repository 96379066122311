import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Button, Popover, Tabs, Drawer, Tooltip } from "antd";

import { Application, taskFrom, useMessage } from "@yulintu/freesia-bootstrap";

import TaskPanel from "./Task";
import UploadPanel from "./Upload";


import Modal from "./Task/Modal";

import "./index.less";

const Addon = function (props) {

    const context = useContext(Application.Context);
    const { setGlobal } = context;

    const [target, setTarget] = useState(false);
    const [visible, setVisible] = useState(false);
    const [activeKey, setActiveKey] = useState("task");

    const [modalObject, setModalObject] = useState(null);

    const [itemsUpload, setItemsUpload] = useState([]);
    const [contextUpload, setContextUpload] = useState({
        uploadingCount: 0,
        uploadingResovles: []
    });

    const tryStartUpload = useCallback(() => {

        if (contextUpload.uploadingCount >= 5)
            return;
        if (!contextUpload.uploadingResovles.length)
            return;

        const rs = contextUpload.uploadingResovles[0];
        contextUpload.uploadingResovles.splice(0, 1);
        contextUpload.uploadingCount++;
        rs();

    }, [contextUpload]);

    const flag = target == null ? {} : null;
    useEffect(() => {
        // document.getElementsByClassName("")
    }, []);

    useEffect(() => {

        setGlobal({
            showTaskObserverModal: (job) => {
                onOpen(job);
            },
            showTaskObserver: () => {
                setVisible(true);
                setActiveKey("task");
            },
            hideTaskObserver: () => {
                setVisible(false);
            },
            showUploadObserver: () => {
                setVisible(true);
                setActiveKey("upload");
            },
            hideUploadObserver: () => {
                setVisible(false);
            },
            observeUpload: (file) => {

                if (file.file.response) {
                    contextUpload.uploadingCount--;
                    tryStartUpload();
                }

                setItemsUpload(items => {

                    let newItems = null;
                    let index = items.findIndex(c => c.file.uid === file.file.uid);
                    if (index > -1) {
                        items[index] = file;
                        newItems = [...items];
                    }
                    else {
                        newItems = [file, ...items];
                    }

                    return newItems;
                });
            },
            lazyUpload: (rs) => {
                contextUpload.uploadingResovles.push(rs);
                tryStartUpload();
            }
        });


    }, [setGlobal, contextUpload, tryStartUpload]);

    useMessage(data => {
        if (data?.sender !== "tasks-observer")
            setVisible(false);
    }, "drawer.shown");

    const onOpen = item => {
        setModalObject(taskFrom(item));
    }

    const renderContent = () => {
        return (
            <Tabs className='tabs gradient-ink-bar full lg' activeKey={activeKey} onChange={e => setActiveKey(e)}>
                <Tabs.TabPane tab="任务" key="task">
                    <TaskPanel visible={visible && activeKey === "task"} scheduleUrl={props.scheduleUrl} scheduleDashboardUrl={props.scheduleDashboardUrl} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="上传" key="upload">
                    <UploadPanel visible={visible && activeKey === "upload"} itemsAll={itemsUpload} />
                </Tabs.TabPane>
            </Tabs>
        );
    }

    return <>
        {/* <Popover visible={visible} content={renderContent()} overlayClassName="tasks-observer-popup" placement="bottomLeft">
        </Popover> */}
        <Tooltip title="任务监控" placement='bottom'>
            <Button
                className="primary-header-button tasks-observer"
                type={'text'}
                onClick={e => { Application.EventEmitter.emit(`drawer.${visible ? "hidden" : "shown"}`, { sender: "tasks-observer" }); setVisible(!visible); }}
                icon={
                    <Application.SystemSvgIcon style={{
                        fontSize: 28,
                        height: 28
                    }} type={"task"}>
                    </Application.SystemSvgIcon>}>
            </Button>
        </Tooltip>
        {
            context.global.contentRef.current ? (
                <Drawer
                    className='tasks-observer-drawer'
                    title={null}
                    width={310}
                    placement="right"
                    closable={false}
                    maskClosable={true}
                    visible={visible}
                    style={{ position: 'absolute' }}
                    getContainer={() => {
                        return context.global.contentRef.current;
                    }}
                    onClose={e => setVisible(false)}
                >
                    {renderContent()}
                </Drawer>
            ) : null}
        <Modal
            scheduleUrl={props.scheduleUrl}
            visible={!!modalObject}
            task={modalObject}
            onCancel={e => setModalObject(null)}
        />
    </>;
}

export default Addon;