import React, { useState } from 'react';
import { Menu } from 'antd';
import { BgColorsOutlined } from '@ant-design/icons';

import Drawer from "./Drawer";
import "./index.less";

const Addon = function (props) {

    const [visible, setVisible] = useState(false);

    return <>
        <Menu.Item {...props} key={"主题设置"} icon={<BgColorsOutlined />} onClick={e => setVisible(true)}>
            主题设置
        </Menu.Item>

        <Drawer visible={visible} onClose={e => setVisible(false)}>
        </Drawer>
    </>
}

export default Addon;