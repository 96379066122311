import React, { useState, useEffect } from 'react';
import { loadMicroApp } from 'qiankun';

import "./index.less";

const MicroApp = function (props) {

    const [id, setId] = useState("microapp_" + `${Math.random()}`.substring(2));

    useEffect(() => {

        console.log("loadMicroApp", id, props.metadata);

        const app = loadMicroApp({
            name: props.metadata.alias,
            entry: props.metadata.props?.src || props.metadata.props?.url,
            container: `#${id}`,
            props: {
                basename: props.metadata.routePath,
            }
        });

        return () => {
            console.log("unloadMicroApp", id, props.metadata);
            app.unmount();
        }

    }, [id, props.metadata]);

    return (
        <div className="layout-micro-app-frame"
            style={props.style}>
            <div id={id}
                className="layout-micro-app"
            >
            </div>
        </div>
    );
}

export default MicroApp;