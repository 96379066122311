import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Pagination, Progress, Typography } from "antd";

import { Application, FileUtils } from "@yulintu/freesia-bootstrap";

import NoData from "../../../components/NoData";

import "./index.less";

const Upload = function (props) {

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);

    const [searchKey, setSearchKey] = useState("");

    useEffect(() => {

        const current = pageIndex - 1;

        new Promise(resolve => {

            let key = searchKey;
            let arr = props.itemsAll;

            let items = arr.filter(c => (c.name ? c.name.toLowerCase().includes(key.toLowerCase()) : false) || (c.alias ? c.alias.toLowerCase().includes(key.toLowerCase()) : false));
            items = _.orderBy(items, [
                c => {
                    return c.file.status === "uploading" ? 1 : 0;
                },
                c => {
                    return c.file.beginTime || 0;
                }
            ], ["desc", "desc"]);

            let itemsPage = items.slice(current * pageSize, current * pageSize + pageSize);

            resolve({ items, itemsPage });

        }).then(data => {
            setItems(data?.itemsPage || []);

        }).finally(() => {
        });

    }, [props.itemsAll, searchKey, pageIndex, pageSize]);

    const getStatus = file => {
        if (file.status === "uploading")
            return "active";
        else if (file.status === "error")
            return "exception";
        else if (file.status === "done")
            return "success";
        else
            return "active";
    }

    return (
        <div className='observer-panel upload-panel layout-v'>
            {
                items.length ? <>
                    <div className='fill h0 vscroll'>
                        {
                            items.map((c, i) => {

                                const status = getStatus(c.file);
                                const percent = c.file.percent.toFixed(0) || 0;
                                return (
                                    <div key={c.file.uid} className='observer-item upload-item'>
                                        <div className='status-text'>
                                            <Application.SystemSvgIcon type="upload1" style={{ fontSize: 32 }} />
                                            <div className='info'>
                                                <div className='name-row' title={c.name}>
                                                    <div className='text-ellipsis text-nowrap name'>{c.name}</div>
                                                    <div className='text-third'>{status === "active" ? FileUtils.getFileSizeText(c.file.size * percent / 100) + "/" + FileUtils.getFileSizeText(c.file.size) : FileUtils.getFileSizeText(c.file.size)}</div>
                                                </div>
                                                <Typography.Paragraph className="description text-second text-wrap" title={c.description} ellipsis={{ rows: 2, expandable: false }}>
                                                    {c.description}
                                                </Typography.Paragraph>
                                            </div>
                                        </div>
                                        <Progress percent={status === "active" ? Math.min(percent, 99) : percent} status={status} strokeWidth={12}></Progress>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <Pagination
                        size="small"
                        simple
                        total={props.itemsAll.length}
                        pageSize={pageSize}
                        current={pageIndex}
                        onChange={(current, size) => { setPageIndex(current); setPageSize(size); }}
                    />
                </> : <NoData></NoData>
            }
        </div >
    );
}

export default Upload;