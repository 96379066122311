import React, { useContext, useState } from 'react';
import { Button, Spin } from "antd";

import ListItem from "./ListItem";
import GreyListItem from "./GreyListItem";
import GalleryItem from "./GalleryItem";

import NoData from "../NoData";

import "./index.less";

const ListGrid = function (props) {

    const children = (props.children && !(props.children instanceof Array) ? [props.children] : (props.children || []));

    return (
        <div className={"freedesign list-grid-v4 " + (props.className || "")} style={{ ...props.style, opacity: props.busy ? .5 : 1 }} >
            {
                children.length ? children.map((c, i) => (
                    <Button
                        key={c.key || i}
                        onDoubleClick={e => props.onDoubleClick && props.onDoubleClick(c.key || i)}
                        onClick={e => props.onClick && props.onClick(c.key || i)}
                        className={'list-grid-v4-item ' + (props.selectedKeys && props.selectedKeys.indexOf(c.key || i) >= 0 ? "item-selected" : "")}>
                        {c}
                    </Button>
                )) : (
                    !props.busy ? (props.nodata || <NoData.NoDataImage01 />) : null
                )
            }
            {
                props.busy ? (
                    <Spin wrapperClassName="full abs0" spinning={props.busy} size="large">
                        <div className='wh100' />
                    </Spin>
                ) : null
            }
        </div>

    );
}

ListGrid.ListItem = ListItem;
ListGrid.GreyListItem = GreyListItem;
ListGrid.GalleryItem = GalleryItem;

export default ListGrid;