import React from 'react';
import { Spin } from 'antd';

import "./index.less";

const Addon = function (props) {

    return (
        <Spin className="full" size={props.metadata.size || "large"} />
    );
}

export default Addon;