import React, { useContext, useState } from 'react';
import { Checkbox } from "antd";

const GalleryItem = function (props) {

    return (
        <div className={"panel hoverable list-grid-v4-item--gallery-item"} onClick={e => props.onClick && props.onClick(e)}>
            {/* <div onClick={e => e.stopPropagation()}>
                <Checkbox checked={props.checked} onChange={e => props.onChecked && props.onChecked(e.target.checked)}>
                </Checkbox>
            </div> */}
            <div className="wh100">{props.children}</div>
        </div>
    );
}

export default GalleryItem;