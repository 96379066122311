import React, { useState, useEffect, useCallback } from 'react';
import { Input } from "antd";

import PropertyGridUtils from "../PropertyGridUtils";

const PasswordEditor = function (props) {

    // const [flag, setFlag] = useState();

    const onChange = (e) => {
        props.source.value = e.target.value;
        props.onChange?.(e.target.value);
        // setFlag(Math.random());
    }

    return (
        <Input.Password
            placeholder={props.source.placeholder || ""}
            value={props.source.value}
            autoComplete={"off"}
            style={props.source.style || undefined}
            onChange={e => onChange(e)}
            {...props.source.props}
        />
    );
}

export default PasswordEditor;

PropertyGridUtils.register("password", (source) => <PasswordEditor source={source} />);