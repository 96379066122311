import { useContext, useEffect } from 'react';

import { Application } from "@yulintu/freesia-bootstrap";

const Addon = function (props) {

    const context = useContext(Application.Context);
    const { setGlobal } = context;

    useEffect(() => {

        setTimeout(() => {
            setGlobal({
                data: {},
            });
        }, 5000);

    }, [setGlobal]);

    return null;
}

export default Addon;