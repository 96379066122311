import React, { useContext } from 'react';
import { Typography } from 'antd';

import "./index.less";

const Title = function (props) {


    return (
        <Typography.Title {...props} id={props.id ? props.id.replace(/ /g, "_") : undefined} className={"anchor-title" + (props.className || "")}>
            <span>{props.children}</span>
            {(props.id && <a href={`#${props.id.replace(/ /g, "_")}`} className="anchor">#</a>) || undefined}
        </Typography.Title>
    );
}

export default Title;