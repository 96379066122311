import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Tag, Drawer, Tooltip, Typography, message, Pagination, Menu, Dropdown, Space, Badge } from "antd";
import { SyncOutlined, PauseOutlined, DownOutlined } from '@ant-design/icons';

import { Application, taskFrom, notifyFrom, useMessage, useWebSocketMessage } from "@yulintu/freesia-bootstrap";

import { NoData, BaseApi } from "@yulintu/freesia-design";

const Addon = function (props) {

    const context = useContext(Application.Context);

    const sendMessageToMe = useCallback(() => {

        if (!context.global.userInfo?.name || !props.apiUrl)
            return;

        const flag = Math.random();

        BaseApi.postJson(`${props.apiUrl}/sessions/notify/by/user/name?userName=${context.global.userInfo.name}`, {
            catalog: flag < 0.5 ? "business" : "system",
            name: "示例通知",
            seed: "samples_" + (flag < 0.5 ? "business" : "system"),
        }).then(data => {

            const sessionId = data?.data?.id;
            if (!sessionId)
                return;

            BaseApi.postJson(`${props.apiUrl}/send/text/to/session?sessionId=${sessionId}`, {
                title: `${flag < 0.5 ? "业务" : "系统"}消息示例`,
                body: "这是消息的正文。随机生成一个数值：" + Math.random(),
            }).then(data => {

                setTimeout(() => {
                    sendMessageToMe();
                }, 3000);


            }).finally(() => {
            });

        }).finally(() => {
        });

    }, [context.global.userInfo?.name, props.apiUrl]);

    useEffect(() => {

        setTimeout(() => {
            sendMessageToMe();
        }, 3000);

    }, [sendMessageToMe]);

    return null;
}

export default Addon;