import Loading from "./Loading";
import HeaderUserMenu from "./HeaderUserMenu";
import ThemeSettingsMenuItem from "./ThemeSettings/MenuItem";
import SubtitleTestButton from "./SubtitleTestButton";
import HeaderTasksObserver from "./HeaderTasksObserver";

export const templates = [
    {
        name: "@system/addons/loading",
        Component: Loading,
        pure: true,
    },
    {
        name: "@system/addons/layout-header-user-menu",
        Component: HeaderUserMenu,
        pure: true,
    },
    {
        name: "@system/addons/header-user-menu",
        Component: HeaderUserMenu,
        pure: true,
    },
    {
        name: "@system/addons/theme-settings-menu-item",
        Component: ThemeSettingsMenuItem,
        pure: true,
    },
    {
        name: "@system/addons/layout-header-tasks-observer",
        Component: HeaderTasksObserver,
        pure: true,
    },
    {
        name: "@system/addons/header-tasks-observer",
        Component: HeaderTasksObserver,
        pure: true,
    },
    {
        name: "@system/subtitle/addons/test",
        Component: SubtitleTestButton,
        pure: true,
    }
];