import React, { useState, useEffect } from 'react';
import { Modal, message, Spin, Input } from 'antd';
import { SearchOutlined, } from '@ant-design/icons';

import { Bootstrap } from "@yulintu/freesia-bootstrap";

import AppGalleryList from "./AppGalleryList";

import "./index.less";

const AppGalleryModal = function (props) {

    const [busy, setBusy] = useState(true);
    const [apps, setApps] = useState([]);
    const [searchKey, setSearchKey] = useState("");

    useEffect(() => {
        setBusy(true);

        setTimeout(() => {

            let res = props.config?.apps || [];

            Bootstrap.resolve(res, undefined, { shouldBuild: false, jsonpCallbackFunction: "apps" }).then(data => {
                setApps(data);
            }).finally(() => {
                setBusy(false);
            });
        }, 1000);

    }, [props.config?.apps]);

    const onClick = (c) => {

        console.log(c);
        if (c.route && c.routePath) {
            props.onConfirm?.(c);
            window.location.href = c.routePath;
            return;
        }
        if (c.url) {
            props.onConfirm?.(c);
            window.location.href = c.url;
            return;
        }

        message.info(c.message || "暂未开通该功能");
    }

    return <>
        <Modal
            wrapClassName={props.metadata?.fullModal !== false ? `fullscreen-dialog-wrap` : null}
            className="backstage-modal white app-gallery-modal"
            title={(
                <div className='layout-h center action-bar'>
                    <div>全部产品</div>
                    <Input.Search className='fill outlined full large mgr40 mgl20'
                        prefix={<SearchOutlined style={{ fontSize: 22, color: "#ACACAC" }} />}
                        enterButton={null}
                        allowClear
                        placeholder={`请输入关键字搜索`}
                        onSearch={e => setSearchKey(e)}
                    />
                </div>
            )}
            visible={props.visible}
            centered={true}
            onCancel={e => props.onCancel && props.onCancel(e)}
            footer={null}>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="70%" viewBox="0 0 450 207.53" preserveAspectRatio="xMinYMin slice" className="background-svg">
                <path d="M443 18a23.47 23.47 0 0 0 .54 5 22 22 0 0 0-32.83 22.6h-.69a16.19 16.19 0 0 0-16.22 16.09c0 .31 0 .61.05.91a19.35 19.35 0 0 0-24.71 13.78h-.49a13.88 13.88 0 0 0-12.11 7.09 24.55 24.55 0 0 0-40.48 18.63v.63a21 21 0 0 0-29.14 12.11 15.57 15.57 0 0 0-25.42 12v.36a14 14 0 0 0-19 4.66 14 14 0 0 0-26.36 3.41 14.86 14.86 0 0 0-23.63-2.85v-.82a18.22 18.22 0 0 0-32.14-11.71 16.91 16.91 0 0 0-25.38-7.55 23.89 23.89 0 0 0-41.16-14 18 18 0 0 0-16.1-10 17.65 17.65 0 0 0-4.51.6v-.11a20 20 0 0 0-20-20 19.74 19.74 0 0 0-5.46.78A23.12 23.12 0 0 0 16.47 42a13.46 13.46 0 0 0-9.66-15 23 23 0 0 0 1.71-8.73A23 23 0 0 0-.5 0v209.53h451V.65A23.65 23.65 0 0 0 443 18z" fill="#bbb" opacity=".1" />
                <path d="M449.43 37.88a18.74 18.74 0 0 0-15.72 29 17 17 0 0 0-21.39 12.67A29 29 0 0 0 366.8 100a13.3 13.3 0 0 0-18.57 12.16 13 13 0 0 0 .07 1.4 24.26 24.26 0 0 0-33.81 19.6 29.88 29.88 0 0 0-50 15.7 14.07 14.07 0 0 0-23.06 5.69 17.22 17.22 0 0 0-27.44.85 16.38 16.38 0 0 0-25.17-7.4 29 29 0 0 0-51.66-9.76 19 19 0 0 0-29.49-7 13.47 13.47 0 0 0-10.94-5.61 13.26 13.26 0 0 0-4.15.66 18.43 18.43 0 0 0-25.28-14.53A31.51 31.51 0 0 0 24.89 77.3 18.53 18.53 0 0 0 6.36 59.56a19.28 19.28 0 0 0-2.42.17 19.78 19.78 0 0 0 1.67-8A19.77 19.77 0 0 0-.5 37.45v172.08h451V37.91c-.35-.02-.71-.03-1.07-.03z" fill="#bbb" opacity=".5" />
                <path d="M439.28 101.77a25.51 25.51 0 0 0 2.37 10.76 29.85 29.85 0 0 0-45.4 25.41v1.19A42.15 42.15 0 0 0 318.71 160a33.36 33.36 0 0 0-62.35 16.43v.58a16.08 16.08 0 0 0-24.11 9.23 17 17 0 0 0-25.45-5.92 17.35 17.35 0 0 0-32.06-12.86 35.14 35.14 0 0 0-63.31-3.46 31.33 31.33 0 0 0-56.17-12.28 16.35 16.35 0 0 0-24.7-12.54 28.91 28.91 0 0 0 3.08-13A29 29 0 0 0 6 97.26a20.38 20.38 0 0 0 .69-5.15A20.16 20.16 0 0 0-.5 76.67v132.86h451v-129a25.65 25.65 0 0 0-11.22 21.24z" fill="#bbb" /></svg>

            <div className="app-list-frame">
                {busy ? (
                    <Spin wrapperClassName="full" spinning={busy} size="large">
                        <div></div>
                    </Spin>
                ) : (<AppGalleryList apps={apps} searchKey={searchKey} onClick={e => onClick(e)} />)
                }
            </div>
        </Modal>
    </>
}

export default AppGalleryModal;