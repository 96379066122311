import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { Switch } from 'react-router-dom'
import { ConfigProvider, Layout, Spin, Menu, Drawer, Button } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import {Lang} from "@yulintu/freesia-react-component-common";
import { Application, Bootstrap } from "@yulintu/freesia-bootstrap";

import ThemeContext from "../../../ThemeContext";
import Header from "./Header";

import "./index.less";

const ClassicLayout = function (props) {

    const context = useContext(Application.Context);

    const contentRef = useRef();

    const [flag, setFlag] = useState();
    const [busy, setBusy] = useState(true);
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [visibleDrawerSwitcher, setVisibleDrawerSwitcher] = useState(false);
    const [selectedKey, setSelectedKey] = useState();
    const [manifests, setManifests] = useState([]);

    const onWindowSizeChanged = useCallback((e) => {
        setVisibleDrawerSwitcher(window.innerWidth < 768);
    }, []);

    const setGlobal = context.setGlobal;

    useEffect(() => {
        setGlobal({
            contentRef: contentRef
        });
    }, [setGlobal, contentRef]);

    useEffect(() => {
        onWindowSizeChanged();
        window.addEventListener("resize", onWindowSizeChanged);

        return () => {
            window.removeEventListener("resize", onWindowSizeChanged);
        }
    }, [onWindowSizeChanged]);


    const initialThemeHeader = () => {

        let key = `${props.metadata.name || props.metadata.alias} Theme Header`;
        const settings = window === window.top ? localStorage.getItem(key) : "";

        let themeHeader = settings || props.metadata.props?.header?.theme || "default";
        return themeHeader;
    }

    const [themeHeader, setThemeHeader] = useState(initialThemeHeader());

    const changeThemeHeader = (theme) => {

        let key = `${props.metadata.name || props.metadata.alias} Theme Header`;
        localStorage.setItem(key, theme);

        setThemeHeader(theme);
    }


    const initialThemeColor = () => {

        let key = `${props.metadata.name || props.metadata.alias} Theme Color`;
        const settings = window === window.top ? localStorage.getItem(key) : "";

        let themeColor = settings || props.metadata.props?.themeColor || "rgb(16 84 191)";

        ConfigProvider.config({
            theme: {
                primaryColor: themeColor,
            },
        });

        return themeColor;
    }

    const [themeColor, setThemeColor] = useState(initialThemeColor());

    const changeThemeColor = (color) => {

        let key = `${props.metadata.name || props.metadata.alias} Theme Color`;
        localStorage.setItem(key, color);

        setThemeColor(color);

        ConfigProvider.config({
            theme: {
                primaryColor: color,
            },
        });
    }


    const initialThemeHeaderStyle = () => {

        let key = `${props.metadata.name || props.metadata.alias} Theme Header Style`;
        const settings = window === window.top ? localStorage.getItem(key) : "";

        let themeHeaderStyle = settings || props.metadata.props?.shadow || "";
        return themeHeaderStyle;
    }

    const [themeHeaderStyle, setThemeHeaderStyle] = useState(initialThemeHeaderStyle());

    const changeThemeHeaderStyle = (style) => {

        let key = `${props.metadata.name || props.metadata.alias} Theme Header Style`;
        localStorage.setItem(key, style);
        setThemeHeaderStyle(style);
    }

    // console.log("Classic Layout", props);

    // useEffect(() => {

    //     if (window.less && props.themeColor && !window.__POWERED_BY_QIANKUN__) {
    //         console.log("change Theme", props.themeColor);
    //         setBusy(true);
    //         window.less.modifyVars?.({
    //             "@primary-color": props.themeColor
    //         }).finally(() => {
    //             setBusy(false);
    //         });
    //     }

    // }, [props.themeColor]);

    useEffect(() => {

        setBusy(true);
        Bootstrap.resolve(props.metadata.children, props.metadata).then(data => {
            setManifests(data);
        }).finally(() => {
            setBusy(false);
        });

    }, [props.metadata]);

    useEffect(() => {

        if (manifests && manifests.length) {
            Bootstrap.resetPermission(manifests, { userInfo: context.global.userInfo });
            setFlag({});
        }

    }, [props.metadata, manifests, context.global.userInfo]);

    const onLoginSuccess = useCallback((userInfo) => {
        setGlobal({ userInfo });
    }, [setGlobal]);

    const renderMenuItem = (c, deep) => {
        c.alias =<Lang.Right>{c.alias}</Lang.Right>
        if (!c.menu)
            return <Menu.Item key={c.key}>
                {Bootstrap.link(c)}
            </Menu.Item>
        return (
            <Menu.SubMenu key={c.key} popupClassName={"header-menu-popup header-menu " + (themeHeader)} title={c.alias} popupOffset={[2, deep === 0 ? 2 : 0]}>
                {
                    c.menu.map(d => {
                        if (d.enabled !== false && d.visible !== false)
                            return renderMenuItem(d, deep + 1);
                    }).filter(d => d)
                }
            </Menu.SubMenu>
        )
    }

    const renderMenu = (matchItem, inDrawer) => {

        return (
            <Menu
                className={inDrawer ? "" : ("main-menu header-menu " + (themeHeader))}
                triggerSubMenuAction="hover"
                theme={themeHeader === "light" || inDrawer ? "light" : "dark"}
                mode={inDrawer ? "inline" : "horizontal"}
                onSelect={e => { setSelectedKey(e.key); setVisibleDrawer(false); }}
                selectedKeys={[(matchItem && matchItem.key) || selectedKey]}
                style={inDrawer ? {} : { marginLeft: 77, height: 60, width: "100%" }}>
                {
                    manifests.map(c => {
                        if (c.enabled !== false && c.visible !== false)
                            return renderMenuItem(c, 0);
                    }).filter(c => c)
                }
            </Menu>
        );
    }

    const renderAddons = (extra) => {

        const factory = context.factory;
        if (!factory)
            return null;

        const addons = (extra || []).map((c, i) => {
            if (!c.template)
                return null;

            let component = null;
            if (typeof c.template === "string")
                component = factory.load(c.template, { ...c.props, metadata: c, key: i }, { key: i });

            return component;

        }).filter(c => c);

        return addons;
    }

    const renderDrawer = (matchItem) => {

        const extra = props.metadata.props?.header?.rightExtra || props.metadata.props?.header?.rightAddons || [];

        return (
            <Drawer className="layout-classic-content-sider-drawer" placement="right" visible={visibleDrawer} onClose={e => setVisibleDrawer(false)} width={"auto"} closeIcon={null} getContainer={false} headerStyle={{ display: "none" }}>
                {visibleDrawerSwitcher ? (
                    <Layout.Sider theme="light" className="layout-classic-content-sider right" collapsible={false}  >
                        {
                            <div className='sider-title'>导航</div>
                        }
                        {
                            renderMenu(matchItem, true)
                        }
                        <div className="addons">
                            {
                                renderAddons(extra)
                            }
                        </div>
                    </Layout.Sider>
                ) : null}
            </Drawer>
        );
    }

    const renderDrawerSwitcher = () => {

        if (visibleDrawerSwitcher)
            return (
                <Button className="primary-header-button drawer-swticher layout-h center2" type={'text'} onClick={e => setVisibleDrawer(true)}>
                    <MenuOutlined />
                </Button>
            );

    }

    const renderContent = (c, deep) => {

        let matchItem = Bootstrap.match(manifests, window.location.pathname, context.global.basename);
        if (!matchItem)
            matchItem = Bootstrap.findDefault(manifests);

        // console.log(matchItem);

        const shadow = themeHeaderStyle || (themeHeader === "light" ? "header" : "content");

        const headerProps = {
            ...props.metadata.props?.header,
            ...props.metadata.props?.header[themeHeader]
        }

        // console.log("systemSubtitle", context.global?.systemSubtitle);

        return (
            <ThemeContext.Provider value={{
                themeHeader,
                changeThemeHeader,
                themeHeaderStyle,
                changeThemeHeaderStyle,
                themeColor,
                changeThemeColor,
            }}>
                <Layout className="layout-classic layout-v" >
                    <Layout.Header className={"layout-classic-header " + (themeHeader) + (shadow === "header" ? " shadow" : "")} style={props.metadata.props?.header?.style}>
                        <Header
                            title={props.metadata.props?.header?.title || props.metadata.alias}
                            subtitle={props.metadata.props?.header?.subtitle}
                            subtitleExtra={props.metadata.props?.header?.subtitleExtra}
                            siteUrl={Bootstrap.linkPath(props.metadata)}
                            {...headerProps}
                            drawerSwitcher={renderDrawerSwitcher()} >
                            {visibleDrawerSwitcher ? null : renderMenu(matchItem)}
                        </Header>
                    </Layout.Header>
                    <Layout.Content ref={contentRef} className={"fill layout-classic-content-frame " + (themeHeader) + (shadow !== "header" ? " shadow" : "")}>
                        {
                            visibleDrawerSwitcher ? renderDrawer(matchItem) : null
                        }
                        <Switch>
                            {
                                Bootstrap.routes(context.factory, manifests, props.metadata, {
                                    config: context.config,
                                    onLoginSuccess: onLoginSuccess
                                })
                            }
                        </Switch>
                    </Layout.Content>
                </Layout >
            </ThemeContext.Provider>
        );
    }

    return busy ? <>
        <Spin wrapperClassName="full" spinning={busy} size="large">
            <div></div>
        </Spin>
    </> : renderContent();
}

export default ClassicLayout;