import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography, Tag, Progress, Pagination, message, Menu, Dropdown, Space } from "antd";
import { SyncOutlined, PauseOutlined, DownOutlined } from '@ant-design/icons';

import { Application, DateTimeUtils, taskFrom } from "@yulintu/freesia-bootstrap";
import NoData from "../../../components/NoData";

import BaseApi from "../../../../utils/apis/BaseApi";

import "./index.less";

const Task = function (props) {

    const context = useContext(Application.Context);

    const history = useHistory();

    const [flag, setFlag] = useState();
    const [intervalId, setIntervalId] = useState();

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);

    const [searchKey, setSearchKey] = useState("");
    const [triggerType, setTriggerType] = useState("all");

    const buildPredicate = (attrs, exts) => {

        let predicate = "";
        for (const key in attrs) {

            const value = attrs[key];
            if (value === null || value === undefined)
                continue;

            if (predicate)
                predicate += " and ";

            if (typeof value === "string")
                predicate += `${key} = '${value}'`;
            if (typeof value === "number")
                predicate += `${key} = ${value}`;
        }

        if (exts) {
            if (predicate)
                predicate += " and ";
            predicate += `(${exts})`;
        }

        return predicate;
    }

    useEffect(() => {

        if (props.visible) {
            setFlag(Math.random());
        }

    }, [props.visible]);

    useEffect(() => {

        setPageIndex(1);

    }, [triggerType]);

    useEffect(() => {

        if (props.visible && !intervalId) {
            const id = setInterval(() => {
                setFlag(Math.random());
            }, 2000);

            setIntervalId(id);
        }
        else if (!props.visible && intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }

    }, [props.visible, intervalId]);

    useEffect(() => {

        if (!context.global.userInfo?.id || !props.scheduleUrl)
            return;

        BaseApi.getJson(`${props.scheduleUrl}/job/count`, {
            predicate: buildPredicate({
                userId: context.global.userInfo?.id,
                triggerType: triggerType === "all" ? null : triggerType
            })
        }).then(data => {
            setTotalCount(data?.data);
        }).finally(() => {
        });

    }, [flag, props.scheduleUrl, context.global.userInfo?.id, triggerType]);

    useEffect(() => {

        if (!context.global.userInfo?.id || !props.scheduleUrl)
            return;

        BaseApi.getJson(`${props.scheduleUrl}/job/list`, {
            predicate: buildPredicate({
                userId: context.global.userInfo?.id,
                triggerType: triggerType === "all" ? null : triggerType
            }),
            page: pageIndex,
            size: pageSize
        }).then(data => {
            setItems(data?.data);
        }).finally(() => {
        });

    }, [flag, pageIndex, pageSize, totalCount, props.scheduleUrl, context.global.userInfo?.id, triggerType]);

    const onOpen = item => {
        context.global.showTaskObserverModal?.(item);
    }

    const onStop = item => {

        BaseApi.getJson(`${props.scheduleUrl}/job/stop/try`, { jobId: item.id })
            .then(data => {
                message.success("停止请求已成功发送，任务开始尝试停止");
            }).finally(() => {
            });
    }

    const getStatus = item => {

        if (item.state !== "ended")
            return "active";
        if (item.hasTerminated)
            return "exception";
        if (item.hasError)
            return "exception";
        if (item.hasStopped)
            return "normal";
        if (item.hasWarn)
            return "success";

        return "success";
    }

    const getTagText = item => {

        if (item.state !== "ended")
            return "进行中";
        if (item.hasTerminated)
            return "有错误";
        if (item.hasError)
            return "有错误";
        if (item.hasWarn)
            return "有警告";
        if (item.hasStopped)
            return "被停止";

        return "已完成";
    }

    const getTagColor = item => {

        if (item.state !== "ended")
            return "processing";
        if (item.hasTerminated)
            return "error";
        if (item.hasError)
            return "error";
        if (item.hasWarn)
            return "warning";
        if (item.hasStopped)
            return "purple";

        return "success";
    }

    const getProgressColor = item => {

        if (item.hasTerminated)
            return "#c5474a";
        if (item.hasError)
            return "#c5474a";
        if (item.hasWarn)
            return "#faad14";
        if (item.hasStopped)
            return undefined;

        return undefined;
    }

    const getPercent = c => {

        const state = getStatus(c);
        const percent = c.currentPercent.toFixed(0) || 0;

        if (state === "active")
            return Math.min(percent, 99);
        else if (state === "success")
            return 100;
        else
            return percent;
    }

    const getTriggerTypeAlias = (value) => {

        if (value === "all")
            return "所有任务";
        if (value === "cron")
            return "定时任务";
        if (value === "manual")
            return "手动任务";
        if (value === "temp")
            return "临时任务";
    }

    const menu = (
        <Menu>
            <Menu.Item key={"all"} onClick={e => setTriggerType("all")}>所有任务</Menu.Item>
            <Menu.Item key={"cron"} onClick={e => setTriggerType("cron")}>定时任务</Menu.Item>
            <Menu.Item key={"manual"} onClick={e => setTriggerType("manual")}>手动任务</Menu.Item>
            <Menu.Item key={"temp"} onClick={e => setTriggerType("temp")}>临时任务</Menu.Item>
        </Menu>
    );

    const onOpenScheduleDashboard = () => {
        window.open(props.scheduleDashboardUrl, "_blank");
        // history.push({ pathname: props.scheduleDashboardUrl });
        // context.global.hideTaskObserver?.();
    }

    return (
        <div className='observer-panel tasks-panel layout-v'>
            <div className='action-bar'>
                <Dropdown overlay={menu} trigger={['click']}>
                    <a className='layout-h center' onClick={e => e.preventDefault()}>
                        {getTriggerTypeAlias(triggerType)}
                        <DownOutlined style={{ fontSize: 13, marginLeft: 3 }} />
                    </a>
                </Dropdown>
                <div className='fill'></div>
                {props.scheduleDashboardUrl ? <a onClick={e => onOpenScheduleDashboard()}>打开任务监控看板</a> : null}
            </div>
            {
                items.length ? <>
                    <div className='fill h0 vscroll'>
                        {
                            items.map((c, i) => {

                                const meta = taskFrom(c);
                                const status = getStatus(c);
                                const duration = status === "active" || !c.durationTime ? DateTimeUtils.prettyPeroidByNow(moment(c.beginTime)) : DateTimeUtils.prettyPeroid(moment.duration(c.durationTime));
                                const tagText = getTagText(c);

                                return (
                                    <div key={c.id} className='observer-item task-item'>
                                        <div className='status-text'>
                                            <Application.SystemSvgIcon type={meta.getSvgIcon()} style={{ fontSize: 38 }} />
                                            <div className='info'>
                                                <div className='name-row' title={c.name}>
                                                    <a className='text-ellipsis text-nowrap name' onClick={e => onOpen(c)}>{c.name}</a>
                                                    {status === "active" ? <Tag className='mgl5' icon={<SyncOutlined spin />} color={getTagColor(c)}>{getTagText(c)}</Tag> : null}
                                                    {status === "active" ? <Button className='stop' onClick={e => onStop(c)} title={"尝试停止任务"}>{<PauseOutlined />}</Button> : null}
                                                    {c.hasStopped ? <Tag className='mgl5' color={"purple"}>{"已手动停止"}</Tag> : null}
                                                </div>
                                                <div className='time'>
                                                    <div className='key'>{status === "active" ? "开始于" : "完成于"}</div>
                                                    <div className='value' title={status === "active" ? c.beginTime : c.endTime}>{DateTimeUtils.prettyDurationShortByNow(moment(status === "active" ? c.beginTime : c.endTime))}</div>
                                                    <div className='key mgl5'>{status === "active" ? "已进行" : "耗时"}</div>
                                                    <div className='value duration text-nowrap text-ellipsis' title={duration}>{duration}</div>
                                                </div>
                                                <Typography.Paragraph className="description text-second text-wrap" title={c.description} ellipsis={{ rows: 2, expandable: false }}>
                                                    {c.currentDescription || c.description || "该任务没有描述"}
                                                </Typography.Paragraph>
                                            </div>
                                        </div>
                                        <Progress className={c.hasWarn ? "has-warn" : ""} percent={getPercent(c)} status={status} strokeColor={getProgressColor(c)} strokeWidth={12}></Progress>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <Pagination
                        size="small"
                        simple
                        total={totalCount}
                        pageSize={pageSize}
                        current={pageIndex}
                        onChange={(current, size) => { setPageIndex(current); setPageSize(size); }}
                    />
                </> : <NoData></NoData>
            }
        </div >
    );
}

export default Task;