import React, { useState, useEffect, useCallback } from 'react';
import { Input } from "antd";

import PropertyGridUtils from "../PropertyGridUtils";

const StringEditor = function (props) {

    // const [flag, setFlag] = useState();

    // useEffect(() => {
    //     setFlag(Math.random());
    // }, [props.source.value]);

    const onChange = (e) => {
        props.source.value = e.target.value;
        props.onChange?.(e.target.value);
        // setFlag(Math.random());
    }

    return props.source.rows ? (
        <Input.TextArea
            className={props.source.readOnly ? "input-readonly" : ""}
            readOnly={props.source.readOnly ? true : false}
            placeholder={props.source.placeholder || ""}
            value={props.source.value}
            rows={props.source.rows}
            maxLength={props.source.maxLength}
            showCount={props.source.showCount}
            onChange={e => onChange(e)} />
    ) : (
        <Input
            className={props.source.readOnly ? "input-readonly" : ""}
            readOnly={props.source.readOnly ? true : false}
            autoComplete={"off"}
            placeholder={props.source.placeholder || ""}
            value={props.source.value}
            maxLength={props.source.maxLength}
            showCount={props.source.showCount}
            onChange={e => onChange(e)} />
    );
}

export default StringEditor;

PropertyGridUtils.register("string", (source) => <StringEditor source={source} />);
PropertyGridUtils.register("text", (source) => <StringEditor source={source} />);