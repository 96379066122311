

import React, { useContext } from 'react';
import { message, notification } from 'antd';

export default function ApiCatchHandler(err) {

    if (!err) {
        notification.error({
            duration: null,
            className: "error-background",
            placement: "bottomRight",
            message: '未知错误',
            description: '发生了一个未知错误，但错误不带任何信息',
        });
        return;
    }

    if (err.status === 500) {
        message.error(err.message || (err + ""), 6);
        return;
    }

    message.error(err.message || (err + ""), 6);
    notification.error({
        duration: null,
        className: "error-background",
        placement: "bottomRight",
        message: '错误',
        description: err.message || (err + ""),
    });
}
