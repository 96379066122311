import React, { useState, useEffect, useCallback } from 'react';
import { InputNumber } from "antd";
import { CheckCard } from '@ant-design/pro-card';

import PropertyGridUtils from "../PropertyGridUtils";

const CheckCardEditor = function (props) {

    // const [flag, setFlag] = useState();

    const onChange = (e) => {
        props.source.value = e;
        props.onChange?.(e);
        // setFlag(Math.random());
    }

    return (
        <CheckCard.Group onChange={e => onChange(e)} value={props.source.value} style={{ width: '100%' }}>
            {
                (props.source.values || []).map(c => (
                    <CheckCard
                        title={c.alias || c.title}
                        description={c.description}
                        value={c.value}
                        {...c.props}
                    />
                ))
            }
        </CheckCard.Group>
    );
}

export default CheckCardEditor;

PropertyGridUtils.register("check-card", (source) => <CheckCardEditor source={source} />);