import React from 'react';
import ReadonlyEditor from "./Editors/ReadonlyEditor";

export default class PropertyGridUtils {

    static editors = {};

    static register(name, uiGetter) {
        name = (name || '').toLowerCase();
        PropertyGridUtils.editors[name] = uiGetter;
    }

    static getEditor(property) {

        let name = property.type;
        name = (name || '').toLowerCase();

        if (property.editable !== undefined && !property.editable)
            return (source) => <ReadonlyEditor source={source} />;
        else if (name in PropertyGridUtils.editors)
            return PropertyGridUtils.editors[name];
        else
            return (source) => <ReadonlyEditor source={source} />;
    }
}