import React, { Component } from 'react';
import { Resizable } from 'react-resizable';
import { Table, Tooltip, Tag } from 'antd';

import { Application, getDataTypeMetadata, metadataFrom } from "@yulintu/freesia-bootstrap";

import './index.less';

const ResizeableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    const meta = restProps.col.columnMetadata ? metadataFrom(restProps.col.columnMetadata) : getDataTypeMetadata(restProps.col.type).instance;

    return (
        <Resizable width={width} height={0} onResize={onResize}>
            <th {...restProps} className={restProps.col.isSelected && !restProps.col.isRowHeader ? "bg-active2" : "th-default"} >
                <div className='layout-h center' style={{ width: restProps.col.width }}>
                    <Application.SvgIcon type={meta.getSvgIcon()} style={{ fontSize: 20 }}></Application.SvgIcon>
                    <div className='td-content mgl5 mgr10'>
                        {restProps.col.isRowHeader ? null : <span className="name" title={restProps.col.title}>{restProps.col.title}</span>}
                        {restProps.col.isRowHeader ? null : <span className="type mgl5" title={meta.getTypeAlias()}>{meta.getTypeAlias()}</span>}
                    </div>
                    {restProps.col.isPrimary ? <Tooltip title={"主键"}><Application.SvgIcon type={"key"} style={{ fontSize: 20 }}></Application.SvgIcon></Tooltip> : null}
                    {restProps.col.columnMetadata?.type === "Geometry" ? <Tooltip title={"空间字段"}><Application.SvgIcon className="mgr10" style={{ fontSize: 20 }} type={"-globe"} /></Tooltip> : null}
                    {restProps.col.columnMetadata?.type === "Geometry" ? <Tooltip title={"空间数据类型"}><Tag color={"magenta"}>{meta.getGeometryTypeAlias()}</Tag></Tooltip> : null}
                    {restProps.col.columnMetadata?.type === "Geometry" ? <Tooltip title={"空间数据坐标系"}><Tag color={"geekblue"}>{meta.getGeometrySrid()}</Tag></Tooltip> : null}

                    <div className='fill'> </div>
                    {(restProps.col.isRowHeader || !restProps.col.headerDropdown) ? null : <div className="dropdown">{restProps.col.headerDropdown}</div>}

                </div>
            </th>
        </Resizable>
    );
};

export default class ResizableDatatable extends Component {

    state = {
        columns: [],
        selectedRows: [],
        selectedItem: null,
    }

    components = {
        header: {
            cell: ResizeableTitle,
        },
    };

    componentWillReceiveProps(nextProps) {
        if (this.state.columns !== nextProps.columns)
            this.setState({ columns: nextProps.columns });
    }

    componentDidMount() {
        this.setState({ columns: this.props.columns });
    }

    handleResize = index => (e, { size }) => {

        if (size.width < 50)
            size.width = 50;

        this.setState(({ columns }) => {
            const nextColumns = [...columns];
            nextColumns[index].width = size.width;
            return { columns: nextColumns };
        });
    };

    onClickRow(rowIndex, record, event) {

        // console.log(record);
        // console.log(rowIndex);

        this.setState({ selectedRows: [rowIndex], selectedItem: record });
    }

    render() {

        const columns = (this.state.columns || []).map((col, index) => ({
            ...col,
            render: (text, item) => {
                return <div className={"td-content " + (col.isSelected ? "bg-active " : "") + (item.key === this.state.selectedItem?.key ? "bg-active " : "") + (col.isRowHeader && !col.isSelected && this.state.selectedRows.length > 0 && item.key === this.state.selectedItem.key ? "bg-active " : "") + (!col.isRowHeader && col.isSelected && this.state.selectedRows.length > 0 && item.key === this.state.selectedItem.key ? "ant-pagination-item-active" : "td-default")} onClick={c => {
                    this.state.columns.forEach(c => c.isSelected = false);
                    col.isSelected = true;
                    this.setState({});
                }} style={{ width: this.state.columns[index].width }}>{col.render ? col.render(text, item) : text}</div>;
            },
            onHeaderCell: column => ({
                width: column.width,
                onResize: this.handleResize(index),
                col: col,
            }),
        }));

        return (
            <Table
                bordered
                {...this.props}
                className={`resizable-datatable ${this.props.className}`}
                components={this.components}
                columns={columns}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => this.onClickRow(rowIndex, record, event), // click row
                    };
                }}> </Table>
        );
    }
}
