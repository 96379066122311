import React from 'react';
import { ConfigProvider, message, notification } from "antd";
import zhCN from 'antd/es/locale/zh_CN';

import "../css/freesia-design.less";

import { Application as ApplicationBootstrap } from "@yulintu/freesia-bootstrap";
import templates from "../templates";

message.config({
    top: 60,
    duration: 2,
    maxCount: 5,
    prefixCls: 'ant4-message',
});

notification.config({
    top: 70,
});

ConfigProvider.config({
    prefixCls: 'ant4'
})

const Application = function (props) {

    return <>
        <ConfigProvider prefixCls="ant4" locale={zhCN} autoInsertSpaceInButton={false} >
            <ApplicationBootstrap {...props} wrapper={Application} templates={[...templates, ...props.templates]} />
        </ConfigProvider>
    </>
}

Application.Core = ApplicationBootstrap.Core;
Application.Context = ApplicationBootstrap.Core.Context;
Application.EventEmitter = ApplicationBootstrap.Core.EventEmitter;
Application.SystemSvgIcon = ApplicationBootstrap.SystemSvgIcon;

export default Application;