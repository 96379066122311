import moment from "moment";
import React, { useState, useEffect, useContext } from 'react';
import { Button, Tag, Form, Spin, message, Table, Select, Modal, Tooltip, Input, Divider } from 'antd';
import { ExclamationCircleOutlined, SearchOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { Application } from '@yulintu/freesia-bootstrap';

import { DateTimeUtils } from "@yulintu/freesia-bootstrap";
import PropertyTable from "../../../../../components/PropertyTable";

import './index.less';

export default function Summary(props) {

    const [items, setItems] = useState([]);
    const [itemsSchedule, setItemsSchedule] = useState([]);
    const [itemsRun, setItemsRun] = useState([]);

    useEffect(() => {

        if (!props.task) {
            setItems([]);
            return;
        }

        setItems([
            {
                key: "任务名",
                value: props.task.info.name,
            },
            {
                key: "任务描述",
                value: props.task.info.description || "该任务没有描述",
            },
            // {
            //     key: "创建时间",
            //     value: props.file.createTime ? DateTimeUtils.prettyDurationLongByNow(moment(props.file.createTime)) : "未知",
            // },
            // {
            //     key: "修改者",
            //     value: props.file.modifyUserName || "未知",
            // },
            // {
            //     key: "修改时间",
            //     value: props.file.modifyTime ? DateTimeUtils.prettyDurationLongByNow(moment(props.file.modifyTime)) : "未知",
            // }
        ]);

        setItemsSchedule([
            {
                key: "运行节点",
                value: props.task.info.nodeId,
            },
            {
                key: "调度器",
                value: props.task.info.triggerController,
            },
            {
                key: "调度方式",
                value: getTriggerType(props.task.info),
            },
        ]);

        const c = props.task?.info || {};
        const status = c ? getStatus(c) : null;
        const duration = status === "active" || !c?.durationTime ? DateTimeUtils.prettyPeroidByNow(moment(c?.beginTime)) : DateTimeUtils.prettyPeroid(moment.duration(c?.durationTime));

        setItemsRun([
            {
                key: "任务状态",
                value: props.task.info.state === "ended" ? "已结束" : "进行中",
            },
            {
                key: "开始时间",
                value: props.task.info.beginTime ? DateTimeUtils.prettyDurationLongByNow(moment(props.task.info.beginTime)) : "未知",
            },
            {
                key: "结束时间",
                value: props.task.info.endTime ? DateTimeUtils.prettyDurationLongByNow(moment(props.task.info.endTime)) : "未知",
            },
            {
                key: "耗时",
                value: (status === "active" ? "已耗时 " : "共耗时 ") + duration
            },
        ]);

    }, [props.task]);

    const getTriggerType = item => {

        if (item.triggerType === "cron")
            return "计划调度";
        if (item.triggerType === "manual")
            return "手动调度";
        if (item.triggerType === "temp")
            return "临时调度";
    }

    const getStatus = item => {

        if (item.state !== "ended")
            return "active";
        if (item.hasTerminated)
            return "exception";
        if (item.hasError)
            return "exception";
        if (item.hasWarn)
            return "success";
        if (item.hasStopped)
            return "normal";

        return "success";
    }

    return (
        <Spin wrapperClassName="full" spinning={false} size="large">
            <div className="h100 layout-v card-settings summary">
                <div className="h100 vscroll">
                    <div className='action-bar layout-h mgb25'>
                        <div className='card-title mgr20'>常规信息</div>
                    </div>
                    <div className='relative'>
                        <PropertyTable dataSource={items} keyWidth={150} style={{ maxWidth: 1024 }} />
                    </div>
                    <div className='action-bar layout-h mgb25 mgt30'>
                        <div className='card-title mgr20'>调度信息</div>
                    </div>
                    <div className='relative'>
                        <PropertyTable dataSource={itemsSchedule} keyWidth={150} style={{ maxWidth: 1024 }} />
                    </div>
                    <div className='action-bar layout-h mgb25 mgt30'>
                        <div className='card-title mgr20'>运行信息</div>
                    </div>
                    <div className='relative'>
                        <PropertyTable dataSource={itemsRun} keyWidth={150} style={{ maxWidth: 1024 }} />
                    </div>
                </div>
            </div>
        </Spin>
    );
}