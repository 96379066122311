import React, { useState, useEffect, useCallback } from 'react';
import _ from "lodash";
import { Tooltip, Form } from "antd";

import { Application } from "@yulintu/freesia-bootstrap";

import PropertyGridUtils from "./PropertyGridUtils";
import PropertyItem from "./PropertyItem";

const PropertySheet = function (props) {

    const renderLabel = useCallback((title, c) => {
        if (props.orient === "v" && props.showDescription) {
            return (
                <div className="layout-v">
                    <Tooltip title={title || c.name} placement="left">
                        <div className="layout-h center">
                            {c.svg ? <Application.SvgIcon className="mgr5" type={c.svg} /> : null}
                            {<div className="name">{c.alias || c.name}</div>}
                            {/* <SvgIconText className={c.dirty ? "property-item-dirty-name" : ""} showIcon={!!c.svg} type={c.svg} spacing='md' text={(c.alias || c.name)} /> */}
                            {c.url ? <a className="sp-left" href={c.url} target="_blank" rel="noreferrer"> <Application.SvgIcon type={"#anticon-Info1"} /></a> : null}
                        </div>
                    </Tooltip>
                    {c.description ? <div className="text-second text-nowrap hstretch" title={c.description}>{c.description}</div> : null}
                </div>
            )
        }
        else
            return (
                <Tooltip title={title || c.name} placement="left">
                    <div className="layout-h center">
                        {c.svg ? <Application.SvgIcon className="mgr5" type={c.svg} /> : null}
                        {<div className="name">{c.alias || c.name}</div>}
                        {/* <SvgIconText className={c.dirty ? "property-item-dirty-name" : ""} showIcon={!!c.svg} type={c.svg} spacing='md' text={(c.alias || c.name)} /> */}
                        {c.url ? <a className="sp-left" href={c.url} target="_blank" rel="noreferrer"> <Application.SvgIcon type={"#anticon-Info1"} /></a> : null}
                    </div>
                </Tooltip>
            )
    }, [props.orient, props.showDescription]);

    const renderEditor = useCallback((property) => {

        const getter = PropertyGridUtils.getEditor(property);
        return getter(property);

    }, []);

    return (
        <div className={"propertygrid-sheet " + (props.className || "")}  >
            <div className="title">
                <div className="title-label">{props.source.name}</div>
            </div>
            <div className="body">
                {
                    props.source.items.map(c => {
                        let title = c.description || c.alias;
                        if (title) {
                            title = `${title}`;
                        }
                        if (c.dirty && c.dirtyText) {
                            title = `[已过时][${c.dirtyText}] ${title}`;
                        }
                        else if (c.dirty) {
                            title = `[已过时] ${title}`;
                        }
                        return (
                            <PropertyItem
                                form={props.form}
                                key={c.name}
                                name={c.name}
                                source={c}
                                hasFeedback
                                label={renderLabel(title, c)}
                                {...(c.formItemLayout || props.formItemLayout)}
                            >
                                {renderEditor(c)}
                            </PropertyItem>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default PropertySheet;