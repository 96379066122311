import moment from "moment";
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Tag, Form, Spin, message, Table, Select, Modal, Tooltip, Input, Divider } from 'antd';
import { ExclamationCircleOutlined, SearchOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { Application } from '@yulintu/freesia-bootstrap';

import { DateTimeUtils } from "@yulintu/freesia-bootstrap";
import PropertyTable from "../../../../../components/PropertyTable";

import './index.less';

export default function Logs(props) {

    const scrollViewerRef = useRef();

    const [logs, setLogs] = useState(props.logs);
    const lastId = props.logs.length ? props.logs[props.logs.length - 1].id : null;

    useEffect(() => {

        setTimeout(() => {
            scrollViewerRef.current?.lastChild?.scrollIntoView();
        }, 200);

    }, [lastId]);

    useEffect(() => {
        setLogs(props.logs);
    }, [props.logs]);

    const getMessageIcon = (c) => {
        if (c.grade === "Info")
            return "info";
        if (c.grade === "Warn")
            return "Group";
        if (c.grade === "Error" || c.grade === "Exception")
            return "error";
        if (c.grade === "Success")
            return "qiyong";

        return "info";
    }

    const Content = useMemo(() => {

        return (
            <div ref={scrollViewerRef} className="h100 vscroll">
                {
                    logs.length ? logs.map((c, i) => {
                        return (
                            <div key={i} className={"log " + (c.grade.toLowerCase()) + (i % 2 === 0 ? " row-al" : "")}>
                                <Application.SystemSvgIcon type={getMessageIcon(c)} />
                                <span>{moment(c.createTime).format("YYYY年MM月DD日 HH:mm:ss：") + c.message}</span>
                            </div>
                        );
                    }) : (
                        <div>没有结果可以显示</div>
                    )
                }
            </div>
        );
    }, [logs]);

    return (
        <Spin wrapperClassName="full" spinning={false} size="large">
            <div className="h100 layout-v card-settings logs">
                {Content}
            </div>
        </Spin >
    );
}