import React from 'react';
import ReactDOM from 'react-dom';

import { Application } from "./bootstrap";

import { } from "./components/tasks/TestTask";
import templates from "./templates";

import './css/index.less';

ReactDOM.render(
	<Application global={{}} templates={templates} options={{ svgIconPrefix: "freesia" }} />,
	document.getElementById('root')
);

