import { Modal, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import './index.less';

export default function FullScreenModal(props) {

    return (
        <Modal destroyOnClose {...props}
            className={`modal-backstage ${props.className || ''}`}
            wrapClassName={`fullscreen-dialog-wrap ${props.wrapClassName || ''}`}
        >
            <Spin wrapperClassName="full" size="large" spinning={props.confirmLoading || false}>{props.children}</Spin>
        </Modal>
    );
}