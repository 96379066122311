import Classic from "./Classic";
import Content from "./Classic/Content";
import DraggableGrid from "./DraggableGrid";
import OneRowGrid from "./OneRowGrid";
import MicroApp from "./MicroApp";
import SamplePage from "./SamplePage";

export const templates = [
    {
        name: "@system/layout-classic",
        Component: Classic,
    },
    {
        name: "@system/layout-classic/content",
        Component: Content,
    },
    {
        name: "@system/layout-draggable-grid",
        Component: DraggableGrid,
    },
    {
        name: "@system/layout-one-row-grid",
        Component: OneRowGrid,
    },
    {
        name: "@system/layout-wrap-grid",
        Component: OneRowGrid,
    },
    {
        name: "@system/layout-microapp",
        Component: MicroApp,
    },
    {
        name: "@system/microapp",
        Component: MicroApp,
    },

    {
        name: "@system/sample-page",
        Component: SamplePage,
    },
    {
        name: "@system/sample-page/code-viewer",
        loader: () => import("./SamplePage/CodeViewer"),
    },
    {
        name: "@system/pages/app-gallery",
        loader: () => import("./AppGallery/AppGalleryPage"),
    }
];