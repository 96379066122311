import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Switch } from 'react-router-dom'
import { Tooltip, Radio, Spin } from 'antd';
import { Application, Bootstrap } from "@yulintu/freesia-bootstrap";

import "./index.less";

const SamplePage = function (props) {

    const context = useContext(Application.Context);

    const [busy, setBusy] = useState(true);
    const [selectedKey, setSelectedKey] = useState();
    const [manifests, setManifests] = useState([]);

    useEffect(() => {
        setBusy(true);
        Bootstrap.resolve(props.metadata.children, props.metadata).then(data => {
            setManifests(data);
        }).finally(() => {
            setBusy(false);
        });
    }, [props.metadata]);

    useEffect(() => {

        if (manifests && manifests.length)
            Bootstrap.resetPermission(manifests, { userInfo: context.global.userInfo });

    }, [props.metadata, manifests, context.global.userInfo]);

    const setGlobal = context.setGlobal;
    const onLoginSuccess = useCallback((userInfo) => {
        setGlobal({ userInfo });
    }, [setGlobal]);

    let matchItem = Bootstrap.match(manifests, window.location.pathname);
    if (!matchItem)
        matchItem = Bootstrap.findDefault(manifests);

    return busy ? <>
        <Spin wrapperClassName="full" spinning={busy} size="large">
            <div></div>
        </Spin>
    </> : <>
        <div className="freesia-design sample-page layout-v">
            <div className="tools layout-h center">
                <Radio.Group onChange={(e) => {
                    setSelectedKey(e.target.value);
                    props.history.push(e.target.value);
                }} value={selectedKey || matchItem?.key}>
                    {
                        manifests.map(c => (
                            <Tooltip key={c.key} title={c.alias}>
                                <Radio.Button key={c.key} value={c.key}>
                                    <Application.SystemSvgIcon type={c.svg} />
                                </Radio.Button>
                            </Tooltip>
                        ))
                    }
                </Radio.Group>
            </div>
            <div className="h0 fill content">
                <Switch>
                    {
                        Bootstrap.routes(context.factory, manifests, props.metadata, {
                            config: context.config,
                            onLoginSuccess: onLoginSuccess
                        })
                    }
                </Switch>
            </div>
        </div>
    </>
}

export default SamplePage;