// import LogViewer from "./components/LogViewer";

const templates = [
    {
        name: "@system/pages/openapi-apps-viewer",
        loader: () => import("./components/AppsViewer"),
    },
    {
        name: "@system/pages/openapi-apps-monitor",
        loader: () => import("./components/AppsMonitor"),
    },
    {
        name: "@system/pages/openapi-app-monitor",
        loader: () => import("./components/AppMonitor"),
    },
    {
        name: "@system/pages/openapi-docs",
        loader: () => import("./components/ApiDocs"),
    },
    {
        name: "@system/pages/swagger-docs",
        loader: () => import("./components/SwaggerDocs"),
    },
    {
        name: "@system/pages/markdown-docs",
        loader: () => import("./components/MarkdownDocs"),
    },
];

export default templates;