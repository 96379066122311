import React, { useState, useEffect, useCallback } from 'react';
import { Alert } from "antd";

import PropertyGridUtils from "../PropertyGridUtils";

const TipsEditor = function (props) {

    return (
        <Alert
            message={props.source.value}
            description={props.source.description || ""}
            type="info"
            showIcon
        />
    );
}

export default TipsEditor;
PropertyGridUtils.register("tips", (source) => <TipsEditor source={source} />);