import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Tooltip } from 'antd';

import "./index.less";

import { Application } from "@yulintu/freesia-bootstrap";
import AppGalleryModal from "../../../AppGallery/AppGalleryModal";

const AppGallery = function (props) {

    const context = useContext(Application.Context);

    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const openAppCenter = () => {
        if (props.metadata?.route)
            window.location.href = props.metadata?.route;
        else
            setIsModalVisible(true);
    }

    return <>
        <Tooltip title={"应用中心"} placement="bottom">
            <Button className="primary-header-button app-gallery" type={'text'} onClick={e => openAppCenter()} icon={
                <Application.SystemSvgIcon style={{
                    fontSize: 24,
                    height: 25
                }} type={"icons8-squared_menu1"}>
                </Application.SystemSvgIcon>}>
            </Button>
        </Tooltip>
        {props.metadata?.route ? null : <AppGalleryModal visible={isModalVisible} metadata={props.metadata} onCancel={e => setIsModalVisible(false)} onConfirm={e => setIsModalVisible(false)} config={context.config} />}
    </>
}

export default AppGallery;