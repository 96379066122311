import { TaskMetadata, registerTask } from "@yulintu/freesia-bootstrap";

export default class Task extends TaskMetadata {

    constructor(opts = {}) {
        super(opts);
    }

    getSvgIcon() {
        return "Excel1";
    }

    isSupport(task) {
        if (task.name.includes("Excel"))
            return true;
        return false;
    }
}

registerTask(Task);