import React, { useState, useEffect, useCallback } from 'react';
import { InputNumber } from "antd";

import PropertyGridUtils from "../PropertyGridUtils";

const IntegerEditor = function (props) {

    // const [flag, setFlag] = useState();

    const onChange = (e) => {
        props.source.value = e;
        props.onChange?.(e);
        // setFlag(Math.random());
    }

    return (
        <InputNumber
            placeholder={props.source.placeholder || ""}
            min={props.source.minValue || undefined}
            max={props.source.maxValue || undefined}
            step={props.source.stepValue || 1}
            precision={0}
            value={props.source.value}
            style={props.source.style || undefined}
            onChange={e => onChange(e)} />
    );
}

export default IntegerEditor;

PropertyGridUtils.register("int", (source) => <IntegerEditor source={source} />);
PropertyGridUtils.register("integer", (source) => <IntegerEditor source={source} />);