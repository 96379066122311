import React, { useContext } from 'react';

import { Application } from "@yulintu/freesia-bootstrap";
import AppGallery from "./AppGallery";
import AppTitle from "./AppTitle";

const Header = function (props) {

    const context = useContext(Application.Context);

    const renderAddonsLeft = () => {

        const factory = context.factory;
        if (!factory)
            return null;

        const addons = (props.leftExtra || props.leftAddons || []).map((c, i) => {
            if (!c.template)
                return null;

            let component = null;
            if (typeof c.template === "string")
                component = factory.load(c.template, { ...c.props, metadata: c, key: i }, { key: i });

            return component;

        }).filter(c => c);

        return addons;
    }

    const renderAddonsRight = () => {

        const factory = context.factory;
        if (!factory)
            return null;

        const addons = (props.rightExtra || props.rightAddons || []).map((c, i) => {
            if (!c.template)
                return null;

            let component = null;
            if (typeof c.template === "string")
                component = factory.load(c.template, { ...c.props, metadata: c, key: i }, { key: i });

            return component;

        }).filter(c => c);

        return addons;
    }

    return <>
        <div className="layout-h center header-content" >
            {props.appGallery === false ? null : <AppGallery metadata={props.appGallery} />}
            <AppTitle
                title={props.title}
                subtitle={props.subtitle}
                subtitleExtra={props.subtitleExtra}
                logo={props.logo || props.logoImage}
                logoSvg={props.logoSvg}
                siteUrl={props.siteUrl}
                logoImageStyle={props.logoImageStyle}
                logoSvgStyle={props.logoSvgStyle} />
            <div className="h100 layout-h center addons">
                {
                    props.drawerSwitcher ? null : renderAddonsLeft()
                }
            </div>
            <div className="fill h100 layout-h menu-container">{props.children}</div>
            <div className="h100 layout-h center addons">
                {
                    props.drawerSwitcher ? props.drawerSwitcher : renderAddonsRight()
                }
            </div>
        </div>
    </>
}

export default Header;